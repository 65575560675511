import { useTranslation } from 'react-i18next';

export const Agreement = () => {

    const { t } = useTranslation()

    return (
        <main>
            <h1>{t('agreement_title')}</h1>
            <span>{t('agreement_content')} </span>
        </main>
    )
}