import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import WalletApi from '../../api/wallet/index.js';

export const UserDetail = () => {
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(0);
    const [cookies, setCookie] = useCookies(['auth']);

    const [amount, setAmount] = useState(0);
    const [frozenAmount, setFrozenAmount] = useState(0);
    const [isBalanceHide, setIsBalanceHide] = useState(false);

    async function getUserData() {
        const response = await WalletApi.getWallet(cookies.auth.token);
        setAmount(response.data.amount);
        setFrozenAmount(response.data.frozenAmount);
    }

    useEffect(() => {
        getUserData();
    }, []);

    return (
        <div className="user-detail-content container">
            <div className="row" style={{marginLeft: "-15px", marginRight: "-15px"}}>
                <div className="user-box col-sm-12 col-md-6">
                    <div className="profile-photo ft-l">
                        <img src="/images/default_avatar_new_1.png" width="70" height="70" className="br-50" />
                    </div>
                    <div className="info">
                        <p className="nickname fs-18">
                            {cookies.auth.username}
                            <span>达人主播</span>
                        </p>
                        <p className="other ofh">
                            <span className="ft-l"> ID：1576707846 </span>
                            <span className="ft-l">手机号：15737378535<i class="tiny material-icons">edit</i></span>
                            <span className="ft-l">微信号：Superly16821<i class="tiny material-icons">edit</i></span>
                        </p>
                    </div>
                </div>

                <div className="wallet-box col-sm-12 col-md-6">
                    <ul className="flex-1 flex flex-jc-sa">
                        <li>
                            <img src="/images/wallet.png" alt="" />
                            <p>
                                <span>$ {amount}</span>
                                <i>账户余额</i>
                            </p>
                        </li>
                        <li>
                            <img src="/images/wallet.png" alt="" style={{opacity: "60%"}} />
                            <p>
                                <span>$ {frozenAmount}</span>
                                <i>冻结金额</i>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}