import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

const Popupbox = () => {
  const [errorMessage, setErrorMessage] = useCookies(['error']);

  return (
    <div>
      {errorMessage.error !== undefined && errorMessage.error !== null && 
      <div id="modal1" className="modal" style={{display: "block", maxHeight: "21%"}}>
          <div className="modal-content">
              <h4>注意</h4>
              <p>{errorMessage.error && errorMessage.error.popupMessage}</p>
          </div>
          <div className="modal-footer">
              <a href="#" className="modal-close waves-effect waves-green btn-flat" onClick={() => setErrorMessage('error', null)}>關閉</a>
          </div>
      </div>
      }
    </div>
  );
}

export default Popupbox;