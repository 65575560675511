import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import WalletApi from '../../api/wallet/index.js';

export const MainContentDetail = () => {
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(0);
    const [cookies, setCookie] = useCookies(['auth']);

    const [amount, setAmount] = useState(0);
    const [frozenAmount, setFrozenAmount] = useState(0);
    const [isBalanceHide, setIsBalanceHide] = useState(false);

    async function getWalletData() {
        const response = await WalletApi.getWallet(cookies.auth.token);
        setAmount(response.data.amount);
        setFrozenAmount(response.data.frozenAmount);
    }

    useEffect(() => {
        getWalletData();
    });
  
    return (
        <div className="detail-cont container">
            <div className="row">
                <div className="detail-head col-sm-12 col-md-3">
                    <p className="head-username">{cookies.auth.username}</p>

                    <div className="auth-status-line">
                        <div className="sub-head">
                        <i class="tiny material-icons">check_circle</i>
                            <span>投放资质未认证，</span>
                            <a className="auth-entry-a" href="#" target="_blank">
                                <span>前往认证<i class="tiny material-icons">keyboard_arrow_right</i></span>
                            </a>
                        </div>
                        {/* <div className="head-help">
                            <a href="/owner/footer/ownerhelp.html" target="_blank">
                                <span style={{"marginRight": "5px"}}>帮助中心</span>
                                <i className="base-iconfont"></i>
                            </a>
                        </div> */}
                    </div>
                </div>

                <div className="money-area col-sm-12 col-md-6">
                    <div className="head-money">
                        <div className="balance">
                            {isBalanceHide ?
                            <span className="balance-two price-ff" style={{"display": "inline"}}>******</span> :
                            <span className="balance-one price-ff" style={{"display": "inline"}}>HKD {amount}</span>
                            }
                            <div className="blance-text">
                                <span>可用余额 </span>
                                <div className="tooltipbox"><i className="tiny material-icons">info_outline</i>
                                    <div className="tooltiptext">账户中可用于下单的金额</div>
                                </div>
                            </div>
                        </div>
                        <div className="freeze">
                            {isBalanceHide ?
                            <span className="freeze-two price-ff" style={{"display": "inline"}}>******</span> :
                            <span className="freeze-one price-ff" style={{"display": "inline"}}>HKD {frozenAmount}</span>
                            }
                            <div className="blance-text">
                                <span>冻结金额 </span>
                                <div className="tooltipbox"><i className="tiny material-icons">info_outline</i>
                                    <div className="tooltiptext">进行中推广任务冻结的金额，不可用于下单</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="func-area col-sm-12 col-md-3">
                    <a className="eye" onClick={() => setIsBalanceHide(!isBalanceHide)}>
                        {isBalanceHide ? 
                        <i class="tiny material-icons">visibility</i> :
                        <i class="tiny material-icons">visibility_off</i>
                        }
                        <span className="label">{isBalanceHide ? "显示数据" : "隐藏数据"}</span>
                    </a>
                    <div className="pay-btn">
                        <NavLink className="nav-link" to='/service_balance' style={{width: "100%", height: "100%", display: "inline-block", fontSize: "14px", color: "#fff"}}>
                            前往充值
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}