import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import NewMediaForm from '../../components/NewMediaForm.js';

import CardApi from '../../api/card/index.js';

export const UserMediaCards = () => {
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(0);
    const [cookies, setCookie] = useCookies(['auth']);
    
    const [cards, setCards] = useState([]);
    

    async function getUserData() {
        const response = await CardApi.listMediaCards(cookies.auth.token);
        setCards(response.data);
    }

    useEffect(() => {
        getUserData();
    }, []);

    const getCardItems = () => {
        const items = [];
        for (const i of cards) {
            console.log(i);
            items.push(
                <div className="card-plat col-sm-12 col-md-4">
                    <p className="flex flex-ai-c cr-p br-4">
                        <img src={`/images/medias/icon_${i.platform}_rect.png`} width="30" height="30" />
                        <span>{i.platform}名: {i.platformUsername}</span>
                    </p>
                </div>
            );
        }

        return items;
    }

    return (
        <div className="user-media-content container">
            <div className="row">
                <div className="user-media-box-title col-sm-12 col-md-12">
                    <div className="hd fs-16">我的名片</div>
                </div>

                <div className="user-media-card-box col-sm-12 col-md-12">
                    <div className="row">
                        {getCardItems()}
                    </div>
                </div>

                <div className="col-sm-12 col-md-12">
                    <div className="new-media-card-collapse-btnbox">
                        <div
                            className="new-media-card-collapse-button flex flex-fd-c flex-ai-c flex-jc-c"
                             data-toggle="collapse"
                             data-target="#newMediaCardCollapse"
                             aria-expanded="false"
                             aria-controls="newMediaCardCollapse"
                             >
                            <span><i class="tiny material-icons">add</i></span>
                            <span data-v-4d313e67="">添加名片</span>
                        </div>
                    </div>
                </div>

                <div className="col-sm-12 col-md-12 collapse" id="newMediaCardCollapse">
                    <hr />
                    <NewMediaForm />
                </div>
            </div>
        </div>
    );
}