
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

export const RelatedNoticeList = (props) => {
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(0);
    const [cookies, setCookie] = useCookies(['auth']);

    const { relatedNotices } = props;

    return (
        <div className="relate-notice-box mb-24">
            <div className="hd">
                <span className="title">猜你喜欢</span>
            </div>
            <div className="bd">
                <div className="like">
                    <ul>
                        <li className="clear">
                            <div className="pic ft-l br-4 cr-p">
                                <img src="/images/no_image_available.jpg" width="90" height="90" className="br-4" />
                            </div>
                            <div className="info">
                                <span className="title title1 cr-p ellipsis">美国timeless玻尿酸原液产品置换招募</span>
                                <span className="plat">
                                    <img width="18" height="18" src="/images/medias/icon_weibo_rect.png" className="br-4" />
                                </span>
                                <span className="fans ofh">
                                    <i className="i1 ft-l">1万</i>
                                    <i className="i2 ft-r">产品测评</i>
                                </span>
                            </div>
                        </li>

                        <li className="clear">
                            <div className="pic ft-l br-4 cr-p">
                                <img src="/images/no_image_available.jpg" width="90" height="90" className="br-4" />
                            </div>
                            <div className="info">
                                <span className="title title1 cr-p ellipsis">美国timeless玻尿酸原液产品置换招募</span>
                                <span className="plat">
                                    <img width="18" height="18" src="/images/medias/icon_weibo_rect.png" className="br-4" />
                                </span>
                                <span className="fans ofh">
                                    <i className="i1 ft-l">1万</i>
                                    <i className="i2 ft-r">产品测评</i>
                                </span>
                            </div>
                        </li>

                        <li className="clear">
                            <div className="pic ft-l br-4 cr-p">
                                <img src="/images/no_image_available.jpg" width="90" height="90" className="br-4" />
                            </div>
                            <div className="info">
                                <span className="title title1 cr-p ellipsis">美国timeless玻尿酸原液产品置换招募</span>
                                <span className="plat">
                                    <img width="18" height="18" src="/images/medias/icon_weibo_rect.png" className="br-4" />
                                </span>
                                <span className="fans ofh">
                                    <i className="i1 ft-l">1万</i>
                                    <i className="i2 ft-r">产品测评</i>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}