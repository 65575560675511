import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import WalletApi from '../../api/wallet/index.js';

export const WalletCard = () => {
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(0);
    const [cookies, setCookie] = useCookies(['auth']);

    const [amount, setAmount] = useState(0);
    const [frozenAmount, setFrozenAmount] = useState(0);
    const [isBalanceHide, setIsBalanceHide] = useState(false);

    async function getWalletData() {
        if (cookies.auth) {
            const response = await WalletApi.getWallet(cookies.auth.token);
            setAmount(response.data.amount);
            setFrozenAmount(response.data.frozenAmount);
        }
    }

    useEffect(() => {
        getWalletData();
    });
  
    return ( 
        <div className="wallet-card wallet-income">
            <p className="wallet-card-title">钱包信息</p>
            <div className="wallet-income-wrap cf" style={{position: "relative"}}>
                <div className="wallet-income-item lf" style={{width: "50%"}}>
                    {isBalanceHide ?
                    <p className="wallet-income-item-num-hide">*********</p> :
                    <p className="wallet-income-item-num">HKD <span>{amount}</span></p>
                    }
                    <div className="blance-text">
                        <span>账户余额 </span>
                        <div className="tooltipbox"><i className="tiny material-icons">info_outline</i>
                            <div className="tooltiptext">账户中可用于下单的金额</div>
                        </div>
                    </div>
                </div>
                <div className="wallet-income-item lf" style={{width: "50%"}}>
                    {isBalanceHide ?
                    <p className="wallet-income-item-num-hide">*********</p> :
                    <p className="wallet-income-item-num">HKD <span>{frozenAmount}</span></p>
                    }
                    <div className="blance-text">
                        <span>冻结金额 </span>
                        <div className="tooltipbox"><i className="tiny material-icons">info_outline</i>
                            <div className="tooltiptext">进行中推广任务冻结的金额，不可用于下单</div>
                        </div>
                    </div>
                </div>

                
                <div className="wallet-income-seen-wrap walletSeenBtn">
                    <i className="wallet-icon-seen-no"></i>
                </div>
            </div>
        </div>
    );
}