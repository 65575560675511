import { useTranslation } from 'react-i18next';
import { WalletCard } from './WalletCard.js';
import { WalletRecharge } from './WalletRecharge.js';
import { ServiceHeader } from '../../components/ServiceHeader.js';
import { ServiceSidebar } from '../../components/ServiceSidebar.js';
import { Footer } from '../../components/Footer.js';

import './serviceBalance.css';

export const ServiceBalance = () => {

    const { t } = useTranslation()

    return (
        <main style={{backgroundColor: "lightgrey"}}>
            <ServiceHeader />
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12 col-md-2'>
                        <ServiceSidebar />
                    </div>
                    <div className='col-sm-12 col-md-10' id='walletboard'>
                        <br />
                        <WalletCard />
                        <br />
                        <WalletRecharge />
                        <br />
                    </div>
                </div>
            </div>
            <Footer />
        </main>
    )
}