import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import UserApi from '../../api/user/index.js';

export const UserNoticeStatistic = () => {
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(0);
    const [cookies, setCookie] = useCookies(['auth']);
    /*
    const [amount, setAmount] = useState(0);
    const [frozenAmount, setFrozenAmount] = useState(0);
    const [isBalanceHide, setIsBalanceHide] = useState(false);
    */

    async function getUserData() {
    //     const response = await UserApi.getWallet(cookies.auth.token);
    //     setAmount(response.data.amount);
    //     setFrozenAmount(response.data.frozenAmount);
    }

    // useEffect(() => {
    //     getUserData();
    // }, []);

    return (
        <div className="user-statistic-content container">
            <div className="row">
                <div className="box-title col-sm-12 col-md-12">
                    <div className="hd fs-16">投放通告统计</div>
                </div>

                <div className="statistic-box col-sm-6 col-md-3">
                    <p className="cr-p">
                        <span>0</span>
                        <i>已发布通告</i>
                    </p>
                </div>
                <div className="statistic-box col-sm-6 col-md-3">
                    <p className="cr-p">
                        <span>0</span>
                        <i>待审核通告</i>
                    </p>
                </div>
                <div className="statistic-box col-sm-6 col-md-3">
                    <p className="cr-p">
                        <span>0</span>
                        <i>已结束通告</i>
                    </p>
                </div>
                <div className="statistic-box col-sm-6 col-md-3">
                    <p className="cr-p">
                        <span>0</span>
                        <i>已取通告</i>
                    </p>
                </div>
            </div>
        </div>
    );
}