
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { NoticeInfo } from './NoticeInfo.js';
import { RelatedNoticeList } from './RelatedNoticeList.js';
import { ServiceHeader } from '../../components/ServiceHeader.js';
import { ServiceSidebar } from '../../components/ServiceSidebar.js';
import { Footer } from '../../components/Footer.js';

import NoticeApi from '../../api/notice/index.js';
import DateUtils from '../../utils/date/index.js';

import './serviceNoticeInfo.css';

export const ServiceNoticeInfo = () => {
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(0);
    const [cookies, setCookie] = useCookies(['auth']);

    const [notice, setNotice] = useState(null);
    const [relatedNotices, setNRelatedNtices] = useState([]);

    async function getNoticeData(noticeId) {
        const response = await NoticeApi.getNotice(
            cookies.auth.token,
            {noticeId}
        );
        
        setNotice(response);
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const noticeid = queryParams.get('noticeid');
        
        getNoticeData(noticeid);
    }, []);

    return (
        <main style={{backgroundColor: "lightgrey"}}>
            <ServiceHeader />
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12 col-md-2'>
                        <ServiceSidebar />
                    </div>
                    <div className='col-sm-12 col-md-7' id='noticeInfoBoard'>
                        {notice && <NoticeInfo notice={notice} />}
                    </div>
                    <div className='col-sm-12 col-md-3' id='relatedNoticeBoard'>
                        <RelatedNoticeList relatedNotices={relatedNotices} />
                    </div>
                </div>
            </div>
            <Footer />
        </main>
    )
}