import i18n from 'i18next';
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';

// const getCurrentHost = process.env.REACT_APP_ENV === 'local' ? 'http://localhost:5173' : 'https://reachprm.com'

i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'zh-HK',
        lng: 'zh-HK',
        interpolation: {
            escapeValue: false
        },
        backend: {
            // loadPath: `${getCurrentHost}/i18n/{{lng}}.json`,
            loadPath: `/i18n/{{lng}}.json`,
        }
    });

export default i18n;
