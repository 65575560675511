import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { LANGUAGES } from '../constants/index.js';
import { Login } from './Login.js';
import { ServiceHeaderDropdown } from './ServiceHeaderDropdown.js';

import './service-header.css';

export const ServiceHeader = () => {
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(0);
    const [cookies, setCookie] = useCookies(['auth']);

    useEffect(() => {
        if (!cookies.auth) {
          window.location.href = "/";
        }
    }, []);
  
    return (
      <header className="navbar navbar-expand sticky-top bg-primary navbar-dark flex-column flex-md-row bd-navbar">
        <a className="navbar-brand mr-0 mr-md-2" href="/">紅量通告</a>

        <div className="navbar-nav-scroll">
          <ul className="navbar-nav bd-navbar-nav flex-row">
            <li className="nav-item px-2">
              <NavLink className="nav-link" to='/service'>主页</NavLink>
            </li>
            <li className="nav-item px-2">
              <NavLink className="nav-link" to='/service_notice'>通告大廳</NavLink>
            </li>
            <li className="nav-item px-2">
              <NavLink className="nav-link" to='/service_kol'>网红广场</NavLink>
            </li>
          </ul>
        </div>

        <ul className="navbar-nav flex-row ml-md-auto d-none d-md-flex">

          <li className="nav-item">
            <a className="nav-link p-3">
              <i class="tinyt material-icons">chat</i>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link p-3">
              <i class="tinyt material-icons">notifications</i>
            </a>
          </li>

          <li className="nav-item dropdown">
            <a className="nav-item nav-link dropdown-toggle mr-md-2" href="#" id="bd-versions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img className="rounded" src="images/default_avatar_new_1.png" alt="Irfan Maulana" title="Irfan Maulana" />
            </a>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="bd-versions">
              <ServiceHeaderDropdown />
            </div>
          </li>
        </ul>
      </header>
    );
}