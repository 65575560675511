import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { LANGUAGES } from '../constants/index.js';
import { Login } from './Login.js';

import WalletApi from '../api/wallet/index.js';

export const ServiceHeaderDropdown = () => {
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(0);
    const [cookies, setCookie] = useCookies(['auth']);

    const [amount, setAmount] = useState(0);
    const [frozenAmount, setFrozenAmount] = useState(0);

    async function getWalletData() {
        const response = await WalletApi.getWallet(cookies.auth.token);
        setAmount(response.data.amount);
        setFrozenAmount(response.data.frozenAmount);
    }

    const handleLogout = async () => {
        setCookie(
            'auth',
            null,
            { path: '/' }
        );
        window.location.href = "/";
    }

    useEffect(() => {
        if (cookies.auth === null || cookies.auth === undefined) {
            window.location.href = "/";
        }
        getWalletData();
    });
  
    return (
      <div className="cms-account-info" style={{"display": "block"}}>
        <i className="cms-account-triangle-icon"></i>
        <div className="cms-account-info-top cf">
          <p className="cms-mine-avatar-wrap lf">
            <img src="images/default_avatar_new_1.png" className="cms-mine-avatar" alt="" />
          </p>
          <div className="cms-account-info-detail lf">
            <p className="cms-account-name">
              {cookies.auth ? cookies.auth.username : ""}
            </p>
          </div>
        </div>
        <br />
        <br />
        <br />
        <hr />
        <br />
        <div className="container">
          <div className='row'>
            <div className="col-6 cms-account-money-wrap">
              <p className="cms-account-money-title">可用余额</p>
              <p className="cms-account-money">
                <span className="cms-account-money-icon">HKD </span>
                <span>{amount}</span>
              </p>
            </div>
            <div className="col-6 cms-account-money-wrap">
              <p className="cms-account-money-title">冻结余额</p>
              <p className="cms-account-money">
                <span className="cms-account-money-icon">HKD </span>
                <span>{frozenAmount}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="cms-account-handle-wrap">
          <NavLink className="cms-account-handle-btn" to='/service_balance'>
            前往充值
          </NavLink>
          <a href="#" className="cms-account-handle-btn" onClick={() => handleLogout()}>退出登录</a>
        </div>
      </div>
    );
}