import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Section_1 } from './Section_1.js';
import { Section_2 } from './Section_2.js';
import { Section_3 } from './Section_3.js';
import { Section_4 } from './Section_4.js';
import { Section_5 } from './Section_5.js';
import { Header } from '../../components/Header.js';
import Count from '../../components/Count.js';

import "./home.css";
import { Footer } from '../../components/Footer.js';
import { NavLink } from 'react-router-dom';

export const Home = () => {
    const [cookies] = useCookies(['auth']);

    const statisticData = ["303338", "23600", "2129", "3470"];

    const { t } = useTranslation()

    return (
        <main>
            <Header />
            <div id="index-banner" className="parallax-container valign-wrapper">
                <div className="section no-pad-bot">
                    <div className="container valign">
                        <br />
                        <h1 className="header center white-text text-darken-2 banner-title">{t('home_title')}</h1>
                        <br />
                        <div className="row center" style={{marginBottom:"5vh"}}>
                            <h5 className="header col s12 light white-text text-darken-2 banner-subtitle">{t('home_content')}</h5>
                        </div>
                        <br/>
                        {cookies.auth ? (
                        <div className="row center">
                            <div className="col align-self-center">
                                <a href="#startPosts" className="btn btn-secondary btn-md active" style={{opacity: "80%"}}>
                                    <NavLink to='/service' style={{color: "#CC0066"}}>{t('menu_service')}</NavLink>
                                </a>
                            </div>
                        </div>
                        ) : ""}
                        <br />
                    </div>
                </div>
                <div data-v-23c54ec5="" className="summary_data_table">
                    <div data-v-23c54ec5="" className="flex_box">
                        <p className="summary_data">
                            <span className="summary_data_s1"><Count number={statisticData[0]} /></span>
                            <span className="summary_data_s2">{t('home_statistic_1_label')}</span>
                        </p>
                        <p className="summary_data">
                            <span className="summary_data_s1"><Count number={statisticData[1]} /></span>
                            <span className="summary_data_s2">{t('home_statistic_2_label')}</span>
                        </p>
                        <p className="summary_data">
                        <span className="summary_data_s1"><Count number={statisticData[2]} /></span>
                            <span className="summary_data_s2">{t('home_statistic_3_label')}</span>
                        </p>
                        <p className="summary_data">
                        <span className="summary_data_s1"><Count number={statisticData[3]} /></span>
                            <span className="summary_data_s2">{t('home_statistic_4_label')}</span>
                        </p>
                    </div>
                </div>
                <div className="parallax">
                    <img src="/images/home_main.png" alt="Unsplashed background img 2" />
                </div>
            </div>

            <div className="container" id="startPosts">
                <div className="row">
                    <div className="col s12 m10 offset-m1 l9 offset-l1">
                        <Section_1 />
                        <hr />
                        <Section_2 />
                        <hr />
                        <Section_3 />
                        <hr />
                        <Section_4 />
                        <hr />
                        <Section_5 />
                        <hr />
                        {/* <Section_6 />
                        <hr />
                        <Section_7 />
                        <hr /> */}
                    </div>
                </div>
            </div>
            <Footer />
        </main>
    )
}