import apiClient from "../http-common.js";

const addMediaCard = async (token, data) => {
  let response;
  try {
    response =  await apiClient.post(
      "/card/add",
      data,
      {
        headers: {
          'token': token
        }
      }
    );
  } catch(error) {
      console.log("error:");
      console.log(error);
      if(error && error.response && error.response.data) {
          return error.response.data;
      }
      return error;
  }
  return { ...response.data, message: "ok" };
};

const getMediaCard = async (token, data) => {
  let response;
  try {
    response =  await apiClient.get(
      "/card/get?cardId="+data.cardId,
      {
        headers: {
          'token': token
        }
      }
    );
  } catch(error) {
      console.log("error:");
      console.log(error);
      if(error && error.response && error.response.data) {
          return error.response.data;
      }
      return error;
  }
  return { ...response.data, message: "ok" };
};

const listMediaCards = async (token, data) => {
  return await apiClient.post(
    "/card/list",
    data,
    {
      headers: {
        'token': token
      }
    }
  );
};

const MediaCardService = {
  addMediaCard,
  getMediaCard,
  listMediaCards,
}

export default MediaCardService;