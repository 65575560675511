import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { LANGUAGES } from '../constants/index.js';

import AuthApi from '../api/auth/index.js';

import './login.css';

export const Login = (props) => {
    const { t } = useTranslation();
    const [cookies, setCookie] = useCookies(['auth']);
    const [errorMessage, setErrorMessage] = useCookies(['error']);
    const { setDisplayLogin } = props;

    const [formId, setFormId] = useState(0);
    const hour = 60 * 60;
    const sevenDay = hour * 24 * 7;

    const [showPopUp, setShowPopUp] = useState(false);
    const [popupMessage, setPopUpMessage] = useState("");

    const handleClickSwitchFormButton = (id) => {
        setFormId(id);
    };

    const [loginData, setLoginData] = useState({
        username: "",
        password: "",
        sevenDay: false
    });
    const handleLoginUsernameChange = (event) => {
        setLoginData({
            ...loginData,
            username: event.target.value
        });
    };
    const handleLoginPasswordChange = (event) => {
        setLoginData({
            ...loginData,
            password: event.target.value
        });
    };
    const handleLoginSevendayCheckboxChange = (event) => {
        setLoginData({
            ...loginData,
            sevenDay: !loginData.sevenDay
        });
    };
    const handleLogin = async () => {
        if (loginData.username.length < 6 || loginData.username.length > 20) {
            setErrorMessage(
                'error',
                { popupMessage: '請輸入6之20位的用戶名' },
                { path: '/', maxAge: 5 }
            );
            return;
        }
        if (loginData.password.length < 6 || loginData.password.length > 20) {
            setErrorMessage(
                'error',
                { popupMessage: '請輸入6到20位的密碼' },
                { path: '/', maxAge: 5 }
            );
            return;
        }
        const result = await AuthApi.login(loginData.username, loginData.password, loginData.sevenDay);
        console.log("result");
        console.log(result);
        if (result.message === "ok") {
            setCookie(
                'auth',
                {
                    username: result.username,
                    userid: result.userId,
                    email: result.email,
                    phone: result.phone,
                    posterVerified: result.posterVerified,
                    token: result.token
                },
                { path: '/', maxAge: loginData.sevenDay ? sevenDay : hour }
            );
            setDisplayLogin(false);
            window.location.href = '/service';
        } else {
            setErrorMessage(
                'error',
                { popupMessage: typeof result.message === "string" ? result.message : "login error" },
                { path: '/', maxAge: 5 }
            );
        }
    }

    const [signupData, setSignupData] = useState({
        username: "",
        countryCode: "+852",
        phoneNo: "",
        password: "",
        repeatedPassword: "",
        readed: false
    });
    const handleSignupUsernameChange = (event) => {
        setSignupData({
            ...signupData,
            username: event.target.value
        });
    };
    const handleSelectCountryCodeChange = (event) => {
        setSignupData({
            ...signupData,
            countryCode: event.target.value
        });
    };
    const handleSignupPhoneNoChange = (event) => {
        setSignupData({
            ...signupData,
            phoneNo: event.target.value
        });
    };
    const handleSignupPasswordChange = (event) => {
        setSignupData({
            ...signupData,
            password: event.target.value
        });
    };
    const handleSignupRepeatedPasswordChange = (event) => {
        setSignupData({
            ...signupData,
            repeatedPassword: event.target.value
        });
    };
    const handleSignupReadedCheckboxChange = (event) => {
        setSignupData({
            ...signupData,
            readed: !signupData.readed
        });
    };
    const handleSignup = async () => {
        if (signupData.username === "") {
            setErrorMessage(
                'error',
                { popupMessage: "請輸入用戶名" },
                { path: '/', maxAge: 5 }
            );
            return;
        }
        if (signupData.username.length < 6 || signupData.username.length > 20) {
            setErrorMessage(
                'error',
                { popupMessage: "請輸入6之20位的用戶名" },
                { path: '/', maxAge: 5 }
            );
            return;
        }
        if (signupData.phoneNo === "") {
            setErrorMessage(
                'error',
                { popupMessage: "請輸入手機號碼" },
                { path: '/', maxAge: 5 }
            );
            return;
        }
        if (signupData.password === "" || signupData.password.length < 6 || signupData.password.length > 20) {
            setErrorMessage(
                'error',
                { popupMessage: "請輸入6到20位的密碼" },
                { path: '/', maxAge: 5 }
            );
            return;
        }
        if (signupData.password !== signupData.repeatedPassword) {
            setErrorMessage(
                'error',
                { popupMessage: "請重複密碼" },
                { path: '/', maxAge: 5 }
            );
            return;
        }
        if (!signupData.readed) {
            setErrorMessage(
                'error',
                { popupMessage: "請確認閱讀《平台服务条款》和《隐私政策》" },
                { path: '/', maxAge: 5 }
            );
            return;
        }
        
        const result = await AuthApi.signup(
            signupData.username,
            signupData.countryCode + signupData.phoneNo,
            signupData.password
        );

        if (result.message === "ok") {
            setDisplayLogin(false);
        } else {
            setErrorMessage(
                'error',
                { popupMessage: typeof result.message === "string" ? result.message : "signup error" },
                { path: '/', maxAge: 5 }
            );
        }
    }

    const signupForm = (
        <div data-v-7cabeb07="" data-v-b9633a6a="" className="el-form el-form--large el-form--label-right login-form" data-type="owner">
            <div data-v-9c344026="" data-v-7cabeb07="" className="el-form-item el-form-item--large is-required asterisk-left login-form_verify-item" vfcode-form-item="">
                <div className="el-form-item__content">
                    <div data-v-9c344026="" className="el-input el-input--large el-input-group el-input-group--append">
                        <div className="el-input__wrapper" tabIndex={-1}>
                            <input className="el-input__inner" maxLength={30} type="text" pattern="[a-zA-Z0][a-zA-Z0-9]+" autoComplete="off" placeholder="账号" value={signupData.username} onChange={handleSignupUsernameChange}  />
                        </div>
                    </div>
                </div>
            </div>
            <div data-v-7cabeb07="" className="el-form-item el-form-item--large asterisk-left form-item-user-name">
                <div className="el-form-item__content">
                    <div data-v-7cabeb07="" className="el-input el-input--large el-input-group el-input-group--prepend">
                        <div className="el-input-group__prepend">
                            <select defaultValue={signupData.countryCode} onChange={handleSelectCountryCodeChange} style={{display: "block", height: "100%", color: "black"}}>
                                <option value="+852">香港 +852</option>
                                <option value="+86">中国 +86</option>
                                <option value="+886">台湾 +886</option>
                                <option value="+60">马来西亚 +60</option>
                                <option value="+65">新加坡 +65</option>
                                <option value="+81">日本 +81</option>
                                <option value="+82">韩国 +82</option>
                                <option value="+1">美国 +1</option>
                                <option value="+1">加拿大 +1</option>
                                <option value="+61">澳大利亚 +61</option>
                                <option value="+64">新西兰 +64</option>
                                <option value="+791">阿联酋 +791</option>
                                <option value="+244">安哥拉 +244</option>
                                <option value="+853">澳门 +853</option>
                                <option value="+55">巴西 +55</option>
                                <option value="+45">丹麦 +45</option>
                                <option value="+49">德国 +49</option>
                                <option value="+7">俄罗斯 +7</option>
                                <option value="+33">法国 +33</option>
                                <option value="+63">菲律宾 +63</option>
                                <option value="+358">芬兰 +358</option>
                                <option value="+31">荷兰 +31</option>
                                <option value="+420">捷克 +420</option>
                                <option value="+41">瑞士 +41</option>
                                <option value="+46">瑞典 +46</option>
                                <option value="+66">泰国 +66</option>
                                <option value="+58">委内瑞拉 +58</option>
                                <option value="+34">西班牙 +34</option>
                                <option value="+39">意大利 +39</option>
                                <option value="+91">印度 +91</option>
                                <option value="+62">印度尼西亚 +62</option>
                                <option value="+44">英国 +44</option>
                                <option value="+84">越南 +84</option>
                            </select>
                        </div>
                        <div className="el-input__wrapper" tabIndex={-1}>
                            <input className="el-input__inner" type="number" autoComplete="off" placeholder="手机号" value={signupData.phoneNo} onChange={handleSignupPhoneNoChange} />
                        </div>
                    </div>
                </div>
            </div>
            <div data-v-4e452332="" data-v-7cabeb07="" className="el-form-item el-form-item--large is-required asterisk-left login-form_verify-item">
                <div className="el-form-item__content">
                    <div data-v-4e452332="" className="el-input el-input--large el-input-group el-input-group--append">
                        <div className="el-input__wrapper" style={{backgroundColor: "lightgray"}}>
                            <input className="el-input__inner" maxLength={10} type="text" autoComplete="off" tabIndex={0} placeholder="短信验证码" disabled />
                        </div>
                        <div className="el-input-group__append">
                            <div data-v-4e452332="" className="send-butn">
                                <button disabled type="button" className="el-button el-button--large is-link">
                                    <span className="">获取验证码</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div data-v-7cabeb07="" className="el-form-item el-form-item--large is-required asterisk-left">
                <div className="el-form-item__content">
                    <div data-v-7cabeb07="" className="el-input el-input--large el-input--suffix">
                        <div className="el-input__wrapper" tabIndex={-1}>
                            <input className="el-input__inner" tabIndex={20} type="password" placeholder="新密码（6-20位数字及字母组成）" value={signupData.password} onChange={handleSignupPasswordChange}  />
                            <span className="el-input__suffix">
                                <span className="el-input__suffix-inner">
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div data-v-7cabeb07="" className="el-form-item el-form-item--large is-required asterisk-left">
                <div className="el-form-item__content">
                    <div data-v-7cabeb07="" className="el-input el-input--large el-input--suffix">
                        <div className="el-input__wrapper" tabIndex={-1}>
                            <input className="el-input__inner" tabIndex={20} type="password" placeholder="再次输入密码" value={signupData.repeatedPassword} onChange={handleSignupRepeatedPasswordChange}  />
                            <span className="el-input__suffix">
                                <span className="el-input__suffix-inner">
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div data-v-7cabeb07="" className="el-form-item el-form-item--large asterisk-left">
                <div className="el-form-item__content">
                    <div data-v-7cabeb07="" className="flex-s-c flex-1 font-color">
                        <label data-v-d060ce07="" className="el-checkbox el-checkbox--large line-height">
                            <span className="el-checkbox__input">
                                <input type="checkbox" checked={signupData.readed} onChange={handleSignupReadedCheckboxChange} style={{position: "relative", left: "auto"}} />
                            </span>
                            <span className="el-checkbox__label">
                                我已阅读
                            </span>
                        </label>
                        <a data-v-7cabeb07="" className="el-link el-link--default fs-12 line-height tc-blue" href="/media/index/rules.html" target="_blank">
                            <span className="el-link__inner">《平台服务条款》</span>
                        </a>
                        <a data-v-7cabeb07="" className="el-link el-link--default fs-12 line-height tc-blue" href="/home/policy/index.html" target="_blank">
                            <span className="el-link__inner">《隐私政策》</span>
                        </a>
                    </div>
                </div>
            </div>
            <div data-v-7cabeb07="" className="login-form-button">
                <button data-v-7cabeb07="" aria-disabled="false" className="el-button el-button--primary el-button--large wq-button--primary"  onClick={() => handleSignup()}>
                    <span className=""> 提交 </span>
                </button>
                <div data-v-7cabeb07="" className="register mt10 flex">
                    <a data-v-7cabeb07="" className="el-link el-link--default fs-12 line-height">
                        <span className="el-link__inner">
                            <span data-v-7cabeb07="" className="pointer tc-blue text-link" onClick={() => handleClickSwitchFormButton(0)}>返回登录</span>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );

    const loginForm = (
        <div data-v-d060ce07="" data-v-b9633a6a="" className="el-form el-form--large el-form--label-right login-form">
            <div data-v-4dfbc719="" data-v-d060ce07="" className="el-form-item el-form-item--large asterisk-left login-form_user-name-item">
                <div className="el-form-item__content">
                    <div data-v-4dfbc719="" className="el-input el-input--large">
                        <div className="el-input__wrapper" tabIndex={-1}>
                            <input type="text" autoComplete="off" tabIndex={0} placeholder="账号" id="username" value={loginData.username} onChange={handleLoginUsernameChange} />
                        </div>
                    </div>
                </div>
            </div>
            <div data-v-d060ce07="" className="el-form-item el-form-item--large is-required asterisk-left">
                <div className="el-form-item__content">
                    <div data-v-d060ce07="" className="el-input el-input--large el-input--suffix">
                        <div className="el-input__wrapper" tabIndex={-1}>
                            <input type="password" autoComplete="off" tabIndex={0} placeholder="密码" id="password" value={loginData.password} onChange={handleLoginPasswordChange} />
                        </div>
                    </div>
                </div>
            </div>
            <div data-v-9c344026="" data-v-d060ce07="" className="el-form-item el-form-item--large asterisk-left login-form_verify-item" style={{"display": "none"}}>
                <div className="el-form-item__content">
                    <div data-v-9c344026="" className="el-input el-input--large el-input-group el-input-group--append">
                        <div className="el-input__wrapper" tabIndex={-1}>
                            <input className="el-input__inner" maxLength={10} type="text" autoComplete="off" tabIndex={0} placeholder="图片验证码" id="verify" />
                        </div>
                        <div className="el-input-group__append">
                            <div data-v-9c344026="" className="login-form_verify-img">
                                <img data-v-9c344026="" src="https://interface.weiq.com/wapi/login/captcha?v=1721210944721" alt="图片验证码" className="pointer" style={{}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div data-v-d060ce07="" className="flex-1 line-height fs-12 change-login-mode mb30">
                <p data-v-d060ce07="" className="flex-e">
                    <span data-v-d060ce07="" className="pointer tc-blue change-type text-link">切换至验证码登录</span>
                </p>
                <div data-v-d060ce07="" className="flex-sb pt10 flex-1">
                    <label data-v-d060ce07="" className="el-checkbox el-checkbox--large line-height">
                        <span className="el-checkbox__input">
                            <input type="checkbox" checked={loginData.sevenDay} onChange={handleLoginSevendayCheckboxChange} style={{position: "relative", left: "auto"}} />
                        </span>
                        <span className="el-checkbox__label">
                            7天内免登录
                        </span>
                    </label>
                    <a data-v-d060ce07="" className="el-link el-link--default fs-12 line-height">
                        <span className="el-link__inner"> 忘记密码 </span>
                    </a>
                </div>
            </div>
            <div data-v-d060ce07="" className="login-form-button">
                <button data-v-d060ce07="" aria-disabled="false" onClick={() => handleLogin()} className="el-button el-button--primary el-button--large wq-button--primary">
                    <span className=""> 登录 </span>
                </button>
                <div data-v-d060ce07="" className="register mt10 flex">
                    <span data-v-d060ce07="" className="fs-12 line-height pointer">
                        还没有账号？点击
                        <span data-v-d060ce07="" className="pointer tc-blue text-link" onClick={() => handleClickSwitchFormButton(1)}>立即注册</span>
                    </span>
                </div>
            </div>
        </div>
    );
  
    return (
      <div className="el-overlay" style={{"zIndex": 1000}}>
        <div role="dialog" aria-modal="true" aria-labelledby="el-id-1024-8" aria-describedby="el-id-1024-9" className="el-overlay-dialog" style={{"display": "flex"}}>
            <div className="el-dialog is-align-center wq-login__dialog" tabIndex={-1}>
                <div id="el-id-1024-9" className="el-dialog__body">
                    <div data-v-b9633a6a="" className="login__dialog">
                        <div data-v-b9633a6a="" className="login__dialog-body">
                            <div data-v-b9633a6a="" className="login__dialog-close" onClick={() => setDisplayLogin(false)}>
                                <img data-v-b9633a6a="" src="/images/login_dialog-close.png" alt="close" />
                            </div>
                            <div data-v-b9633a6a="" className="login__dialog-title flex-sb-c">{formId == 0 ? "账号登录" : "免费注册"}</div>
                            {formId == 0 && loginForm}
                            {formId == 1 && signupForm}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}