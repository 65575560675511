import { useTranslation } from 'react-i18next';

export const Section_1 = () => {

    const { t } = useTranslation()

    return (
        <div className="post-preview top-section">
            <a href="#">
                <h4 className="post-title titleFont">{t('home_section1_title')}</h4>
                <p>{t('home_section1_content')}</p>
            </a>
        </div>
    )
}