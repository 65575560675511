import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import WalletApi from '../../api/wallet/index.js';

export const WalletRecharge = () => {
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(0);
    const [cookies, setCookie] = useCookies(['auth']);

    const [selectedTab, setSelectedTab] = useState(0);
    const [depositAmount, setDepositAmount] = useState(1000);
    const [withdrawAmount, setWithdrawAmount] = useState(0);

    const [showPopUp, setShowPopUp] = useState(false);
    const [popupMessage, setPopUpMessage] = useState("");

    const handleDepositBoxChange = (event) => {
        console.log(event.target.value);
        setDepositAmount(parseInt(event.target.value, 10));
    };
    
    const handleDeposit = async () => {
        const result = await WalletApi.walletDeposit(cookies.auth.token, depositAmount);
        console.log("result");
        console.log(result);
        if (result.message === "ok") {
            window.location.reload();
        } else {
            setPopUpMessage(typeof result.message === "string" ? result.message : "deposit error");
            setShowPopUp(true);
            setTimeout(() => {
                setShowPopUp(false);
            }, 5000);
        }
    }
  
    return (
        <div className="wallet-recharge">
            <div className="wallet-tab-wrap" style={{marginBottom: "-10px"}}>
                <p className="wallet-tab-inner cf" style={{marginBottom: 0}}>
                    <a href="#" onClick={() => setSelectedTab(0)} className={`lf wallet-tab-item ${selectedTab === 0 ? " active" : ""}`}>在线充值</a>
                    <a href="#" onClick={() => setSelectedTab(1)} className={`lf wallet-tab-item ${selectedTab === 1 ? " active" : ""}`}>线下转账</a>
                    <a href="#" onClick={() => setSelectedTab(2)} className={`lf wallet-tab-item ${selectedTab === 2 ? " active" : ""}`}>提款</a>
                </p>
            </div>
            <div className="wallet-card-form" id="myWallet">
                {selectedTab === 0 &&
                <div className="addContForm">
                    <dl className="cf">
                        <dt>充值金额：</dt>
                        <dd className="rechargeBox">
                            <a className={`${depositAmount === 1000 ? "se" : ""}`} href="#" onClick={() => setDepositAmount(1000)}><span className="wallet-money-sign">HKD </span><span className="wallet-money-num">1000</span></a>
                            <a className={`${depositAmount === 2000 ? "se" : ""}`} href="#" onClick={() => setDepositAmount(2000)}><span className="wallet-money-sign">HKD </span><span className="wallet-money-num">2000</span></a>
                            <a className={`${depositAmount === 5000 ? "se" : ""}`} onClick={() => setDepositAmount(5000)}><span className="wallet-money-sign">HKD </span><span className="wallet-money-num">5000</span></a>
                            <a className={`${depositAmount === 8000 ? "se" : ""}`} href="#" onClick={() => setDepositAmount(8000)}><span className="wallet-money-sign">HKD </span><span className="wallet-money-num">8000</span></a>
                            <a className={`${depositAmount === 10000 ? "se" : ""}`} href="#" onClick={() => setDepositAmount(10000)}><span className="wallet-money-sign">HKD </span><span className="wallet-money-num">10000</span></a>
                            <a href="#" onClick={() => setDepositAmount(0)} style={{verticalAlign: "top", position: "relative"}}>
                                <input className="wallet-custome-inp" type="number" maxLength="10" size="18" title="其他金额" style={{paddingLeft: "48px", textDecoration: "none", borderBottom: 0}} onChange={handleDepositBoxChange} value={depositAmount} />
                                <span className="wallet-money-sign" style={{position: "absolute",  left: "5px", top: "12px"}}>HKD </span>
                                <span onClick={() => setDepositAmount(0)} style={{position: "absolute",  right: "5px", top: "5px"}}><i class="tiny material-icons">clear</i></span>
                            </a>
                        </dd>
                    </dl>
                    <dl className="cf">
                        <dt>应付金额：</dt>
                        <dt><span className="wallet-money-sign">HKD </span><span className="wallet-money-num">{depositAmount}</span></dt>
                    </dl>
                    {/* <dl className="cf">
                        <dt>选择支付方式：</dt>
                    </dl> */}
                    {/* <div className="cf">
                        <p className="wallet-recharge-type-item lf active" data-type="1"><i className="wallet-recharge-alipay-icon"></i><span>支付宝</span></p>
                        <p className="wallet-recharge-type-item lf" data-type="2"><i className="wallet-recharge-wechat-icon"></i><span>微信支付</span></p>
                        <input className="walletRechargeType" type="hidden" name="type" value="1" />
                    </div> */}
                    <br />
                    <div className="wallet-recharge-btn-wrap">
                        <input type="hidden" name="money" id="pay" value="1000" />
                        <a href="#" className="wallet-recharge-btn" onClick={() => handleDeposit()}>充值</a>
                    </div>
                    <p className="wallet-recharge-notice">充值时，如遇到未及时到账情况，系统会自动核查，在充值当天入账，请及时关注。</p>
                </div>
                }
                {selectedTab === 1 &&
                <div className="addContForm">线下转账</div>
                }
                {selectedTab === 2 &&
                <div className="addContForm">提款</div>
                }
            </div>
            {showPopUp && 
            <div id="modal1" className="modal" style={{display: "block", maxHeight: "21%"}}>
                <div className="modal-content">
                    <h4>注意</h4>
                    <p>{popupMessage}</p>
                </div>
                <div className="modal-footer">
                    <a href="#" className="modal-close waves-effect waves-green btn-flat" onClick={() => setShowPopUp(false)}>關閉</a>
                </div>
            </div>
            }
        </div>
    );
}