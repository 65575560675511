import { useCookies } from 'react-cookie';
import apiClient from "../http-common.js";

class Auth {
    async signup(username, phone, password) {
        let response;
        try {
            response = await apiClient.post("/auth/signup", {
                username,
                phone,
                password,
            });
        } catch(error) {
            console.log("error:");
            console.log(error);
            if(error && error.response && error.response.data) {
                return error.response.data;
            }
            return error;
        }
        return { ...response.data, message: "ok" };
    };

    async login(username, password, isSevenDays) {
        let response;
        try {
            response = await apiClient.post("/auth/signin", {
                username, password, isSevenDays
            });
        } catch(error) {
            console.log("error.response:");
            console.log(error.response);
            return error.response.data;
        }
        return { ...response.data, message: "ok" };
    };

    // logout() {
    //     const [setCookie] = useCookies(['auth']);
    //     setCookie('auth', null);
    //     return apiClient.post("/auth/signout").then((response) => {
    //         return response.data;
    //     });
    // };

    // getCurrentUser() {
    //     const [cookies, setCookie] = useCookies(['auth']);
    //     return cookies.auth;
    //     // return JSON.parse(localStorage.getItem("user"));
    // };
}

export default new Auth();