import { useTranslation } from 'react-i18next';
import { MainContentDetail } from './MainContentDetail.js';
import { RedPromotion } from './RedPromotion.js';
import { StoreRedRecommend } from './StoreRedRecommend.js';
import { GoodRedRecommend } from './GoodRedRecommend.js';
import { ServiceHeader } from '../../components/ServiceHeader.js';
import { ServiceSidebar } from '../../components/ServiceSidebar.js';
import { Footer } from '../../components/Footer.js';

import './service.css';

export const Service = () => {

    const { t } = useTranslation()

    return (
        <main style={{backgroundColor: "lightgrey"}}>
            <ServiceHeader />
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12 col-md-2'>
                        <ServiceSidebar />
                    </div>
                    <div className='col-sm-12 col-md-10' id='serviceboard'>
                        <br />
                        <MainContentDetail />
                        <br />
                        <RedPromotion />
                        <br />
                        {/* <StoreRedRecommend />
                        <br />
                        <GoodRedRecommend />
                        <br /> */}
                    </div>
                </div>
            </div>
            <Footer />
        </main>
    )
}