import { useTranslation } from 'react-i18next';
import { ServiceHeader } from '../../components/ServiceHeader.js';
import { ServiceSidebar } from '../../components/ServiceSidebar.js';
import { Footer } from '../../components/Footer.js';
import { NoticeOrderStatistic } from './NoticeOrderStatistic.js';
import { UserDetail } from './UserDetail.js';
import { UserMediaCards } from './UserMediaCards.js';
import { UserNoticeStatistic } from './UserNoticeStatistic.js';

import './userProfile.css';

export const UserProfile = () => {

    const { t } = useTranslation()

    return (
        <main style={{backgroundColor: "lightgrey"}}>
            <ServiceHeader />
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12 col-md-2'>
                        <ServiceSidebar />
                    </div>
                    <div className='col-sm-12 col-md-10' style={{ paddingLeft: 0, paddingRight: "30px" }}>
                        <UserDetail />
                        <UserNoticeStatistic />
                        <NoticeOrderStatistic />
                        <UserMediaCards />
                    </div>
                </div>
            </div>
            <Footer />
        </main>
    )
}