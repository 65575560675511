import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import CardApi from '../api/card/index.js';

import './new-media-form.css';

const NewMediaForm = () => {
  const { i18n, t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(0);
  const [cookies, setCookie] = useCookies(['auth']);
  const [errorMessage, setErrorMessage] = useCookies(['error']);

  const [newCardData, setNewCardData] = useState({
    profileImage: "images/default_avatar_new_1.png",
    platform: "bili",
    primaryMarket: "hk",
    platformUsername: "",
    platformId: "",
    fansCount: 0,
    likeCount: 0,
    gender: 1,
    expectAreas: [],
    imageQuote: 0,
    videoQuote: 0
  });
  const handlePlatformChange = (event) => {
    setNewCardData({
      ...newCardData,
      platform: event.target.value
    });
  };
  const handleMarketChange = (event) => {
    setNewCardData({
      ...newCardData,
      primaryMarket: event.target.value
    });
  };
  const handlePlatUsernameChange = (event) => {
    setNewCardData({
      ...newCardData,
      platformUsername: event.target.value
    });
  };
  const handlePlatIdChange = (event) => {
    setNewCardData({
      ...newCardData,
      platformId: event.target.value
    });
  };
  const handleFansCountChange = (event) => {
    setNewCardData({
      ...newCardData,
      fansCount: event.target.value
    });
  };
  const handleLikeCountChange = (event) => {
    setNewCardData({
      ...newCardData,
      likeCount: event.target.value
    });
  };
  const handleGenderChange = (gender) => {
    setNewCardData({
      ...newCardData,
      gender: gender
    });
  };
  function removeElement(array, elementToRemove) {
    array.forEach((item, index) => {
      if (item === elementToRemove) {
        array.splice(index, 1);
      }
    });
    return array;
  }
  const handleExpectAreasChange = (expect) => {
    if (newCardData.expectAreas.includes(expect)) {
      removeElement(newCardData.expectAreas, expect);
    } else {
      setNewCardData({...newCardData, expectAreas: [...newCardData.expectAreas, expect]});
    }
  };
  const handleImageQuoteChange = (event) => {
    setNewCardData({
      ...newCardData,
      imageQuote: event.target.value
    });
  };
  const handleVideoQuoteChange = (event) => {
    setNewCardData({
      ...newCardData,
      videoQuote: event.target.value
    });
  };

  const handleNewMediaCard = async () => {
        if (newCardData.platformUsername === "") {
            setErrorMessage(
                'error',
                { popupMessage: "請輸入平台用戶名" },
                { path: '/', maxAge: 5 }
            );
            return;
        }
        if (newCardData.platformId === "") {
            setErrorMessage(
                'error',
                { popupMessage: "請輸入平台ID" },
                { path: '/', maxAge: 5 }
            );
            return;
        }
        
        const result = await CardApi.addMediaCard(
            cookies.auth.token,
            newCardData
        );

        if (result.message !== "ok") {
            setErrorMessage(
                'error',
                { popupMessage: typeof result.message === "string" ? result.message : "new media card error" },
                { path: '/', maxAge: 5 }
            );
        } else {
            window.location.href = '/user_profile';
        }
    }


  const expectGroup = [
        "园艺","舞蹈","剧情搞笑","颜值","旅行","汽车","商业财经","摄影","游戏","教育",
        "生活记录","兴趣爱好","房产","音乐","才艺技能","生活","二次元","图文控",
        "影视娱乐","文化艺术","萌娃","三农","美妆个护","食饮生鲜","家居生活","服饰箱包",
        "母婴亲子","3C电器","运动健身","萌宠","其他"
  ];
  const generateExpectCheckboxGroup = () => {
      const items = [];

      for (const expect of expectGroup) {
          items.push(
              <label className="checkbox-container">{expect}
                  {/* <span className="el-checkbox__input">
                      <span className="el-checkbox__inner"></span>
                      <input type="checkbox" aria-hidden="false" className="el-checkbox__original checked" value="" />
                  </span> */}
                  <input type="checkbox" onClick={() => handleExpectAreasChange(expect)} className={`${newCardData.expectAreas.includes({expect}) ? "checked" : ""}`} />
                  <span className="checkmark"></span>
              </label>
          );
      }

      return items;
  }

  const marketGroup = [
        {id: "hk", name: "香港特别行政区"},{id: "macao", name: "澳门特别行政区"},
        {id: "gdp", name: "广东省"},{id: "bj", name: "北京市"},
        {id: "sh", name: "上海市"},{id: "tj", name: "天津市"},
        {id: "hebp", name: "河北省"},{id: "sxp", name: "山西省"},
        {id: "jlp", name: "吉林省"},{id: "hljp", name: "黑龙江省"},
        {id: "jsp", name: "江苏省"},{id: "zjp", name: "浙江省"},
        {id: "ahp", name: "安徽省"},{id: "fjp", name: "福建省"},
        {id: "jxp", name: "江西省"},{id: "sdp", name: "山东省"},
        {id: "henp", name: "河南省"},{id: "hubp", name: "湖北省"},
        {id: "hunp", name: "湖南省"},{id: "gx", name: "广西壮族自治区"},
        {id: "hanp", name: "海南省"},{id: "cq", name: "重庆市"},
        {id: "scp", name: "四川省"},{id: "gzp", name: "贵州省"},
        {id: "ynp", name: "云南省"},{id: "xzd", name: "西藏自治区"},
        {id: "xxp", name: "陕西省"},{id: "gxp", name: "甘肃省"},
        {id: "qhp", name: "青海省"},{id: "nxd", name: "宁夏回族自治区"},
        {id: "xjd", name: "新疆维吾尔自治区"},{id: "nmgd", name: "内蒙古自治区"},
        {id: "llp", name: "辽宁省"},{id: "other", name: "其他"}
  ];

  const generateMarketListItemGroup = () => {
      const items = [];

      for (const market of marketGroup) {
          items.push(
              <option value={market.id}>{market.name}</option>
          );
      }

      return items;
  }

  return (
    <div className="new-media-form container">
      <div className="new-media-form-header">
        <span className="title">名片信息</span>
      </div>
      <div className="new-media-form-body row">
        <div className="el-form-item  col-sm-12">
          <label for="profile_photo" className="el-form-item__label">平台头像</label>
          <div className="el-form-item__content">
            <div className="flex">
              <div className="upload">
                <div className="show">
                  <div tabindex="0" className="el-upload el-upload--picture-card">
                    <p type="file" name="file" accept=".jpg, .png" className="upload-area flex flex-fd-c flex-jc-c flex-ai-c">
                      <i class="tiny material-icons">add</i>
                      <span className="upload-tip">上传平台头像</span>
                    </p>
                    <input type="file" name="file" accept=".jpg, .png" className="el-upload__input" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="el-form-item ml24 col-sm-12 col-md-6">
          <label for="french" className="el-form-item__label">平台</label>
          <div className="el-form-item__content">
            <div className="address-select">
              <select class="form-select" defaultValue={newCardData.platform} onChange={handlePlatformChange} aria-label="new media platform select">
                <option value="bili">哔哩哔哩</option>
                <option value="douyin">抖音</option>
                <option value="ks">快手</option>
                <option value="redbook">小红书</option>
                <option value="weibo">微博</option>
                <option value="weixin">微信</option>
                <option value="zhihu">知乎</option>
              </select>
            </div>
          </div>
        </div>
        <div className="flex flex-jc-sb col-sm-12 col-md-6">
          <div className="el-form-item w50">
            <label for="region" className="el-form-item__label">市场投放区</label>
            <div className="el-form-item__content">
              <div className="address-select">
                <select class="form-select" defaultValue={newCardData.primaryMarket} onChange={handleMarketChange} aria-label="new media market select">{generateMarketListItemGroup()}</select>
              </div>
            </div>
          </div>
        </div>
        <div className="el-form-item is-required col-sm-12 col-md-6">
          <label for="title" className="el-form-item__label">平台昵称</label>
          <div className="el-form-item__content">
            <div className="el-input">
              <input type="text" autocomplete="off" className="el-input__inner" value={newCardData.platformUsername} onChange={handlePlatUsernameChange} />
            </div>
          </div>
        </div>
        <div className="el-form-item ml24 is-required col-sm-12 col-md-6">
          <label for="account_id" className="el-form-item__label">平台ID</label>
          <div className="el-form-item__content">
            <div className="el-input">
              <input type="text" autocomplete="off" className="el-input__inner" value={newCardData.platformId} onChange={handlePlatIdChange} />
            </div>
          </div>
        </div>
        <div className="el-form-item is-required col-sm-12 col-md-6">
          <label for="number_fans" className="el-form-item__label">粉丝数</label>
          <div className="el-form-item__content">
            <div className="el-input">
              <input type="number" autocomplete="off" className="el-input__inner" value={newCardData.fansCount} onChange={handleFansCountChange} />
            </div>
          </div>
        </div>
        <div className="el-form-item ml24 is-required col-sm-12 col-md-6">
          <label for="great_hide_number" className="el-form-item__label">赞藏数</label>
          <div className="el-form-item__content">
            <div className="el-input">
              <input type="number" autocomplete="off" className="el-input__inner" value={newCardData.likeCount} onChange={handleLikeCountChange} />
            </div>
          </div>
        </div>
        <div className="el-form-item col-sm-12 col-md-6">
          <label for="sex" className="el-form-item__label">性别</label>
          <div className="el-form-item__content">
            <div className="model flex br-4">
              <span className={`flex-1 ${newCardData.gender === 1 ? "active" : ""}`} onClick={() => handleGenderChange(1)}>男</span>
              <span className={`flex-1 ${newCardData.gender === 0 ? "active" : ""}`} onClick={() => handleGenderChange(0)}>女</span>
            </div>
          </div>
        </div>
        <div className="el-form-item col-sm-12">
          <div className="el-form-item__content">
            <div className="el-form-item">
              <label className="el-form-item__label">达人领域</label>
              <div className="el-form-item__content">
                {generateExpectCheckboxGroup()}
              </div>
            </div>
          </div>
        </div>
        <div className="el-form-item col-sm-12 col-md-6">
          <label for="no_soft_image_price" className="el-form-item__label">图文报价</label>
          <div className="el-form-item__content">
            <div className="el-input el-input--suffix">
              <input type="number" autocomplete="off" placeholder="" oninput="value=value.replace(/^[0]+[0-9.]*$/gi,&quot;&quot;)" className="el-input__inner" value={newCardData.imageQuote} onChange={handleImageQuoteChange} />
            </div>
          </div>
        </div>
        <div className="el-form-item col-sm-12 col-md-6">
          <label for="no_soft_video_price" className="el-form-item__label">视频报价</label>
          <div className="el-form-item__content">
            <div className="el-input el-input--suffix">
              <input type="number" autocomplete="off" placeholder="" oninput="value=value.replace(/^[0]+[0-9.]*$/gi,&quot;&quot;)" className="el-input__inner" value={newCardData.videoQuote} onChange={handleVideoQuoteChange} />
            </div>
          </div>
        </div>
        <div className="operation">
          <button type="button" className="el-button el-button--primary" onClick={() => handleNewMediaCard()}><span>添加名片</span></button>
        </div>
      </div>
    </div>
  );
}

export default NewMediaForm;