import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { LANGUAGES } from '../constants/index.js';

import './service-sidebar.css';

export const ServiceSidebar = () => {
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(0);
    const [cookies, setCookie] = useCookies(['auth']);

    const handleLogout = async () => {
        setCookie(
          'auth',
          null,
          { path: '/' }
        );
        window.location.href = "/";
    }
  
    return (
      <aside className="d-none d-md-block bg-light sidebar">
        <div className="sidebar-sticky">

          <h6 className="sidebar-heading">
            <span>主功能</span>
          </h6>

          <ul className="nav flex-column">
            <li className="nav-item">
              <NavLink className="nav-link" to='/service'>
                <i class="nav-link-icon small material-icons">menu</i>总览
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to='/service_notice?action'>
                <i class="nav-link-icon small material-icons">store</i>種草/探店任務
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to='/service_kol'>
                <i class="nav-link-icon small material-icons">people</i>网红广场
              </NavLink>
            </li>
          </ul>

          <h6 className="sidebar-heading">
            <span>我的工具</span>
          </h6>
          <ul className="nav flex-column">
            <li className="nav-item has-child">
              <NavLink className="nav-link" to='/user_profile'>
                <i class="nav-link-icon small material-icons">account_box</i>个人中心
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to='/service_balance'>
                <i class="nav-link-icon small material-icons">account_balance_wallet</i>钱包
              </NavLink>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#" onClick={() => handleLogout()}>
                <i class="nav-link-icon small material-icons">exit_to_app</i>登出
              </a>
            </li>
          </ul>

        </div>
      </aside>
    );
}