import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_RQN_SVC,
  timeout: 5000,
  headers: {
    // 'Authorization': `Bearer ${"token"}`,
    "Content-type": "application/json",
    "Allow-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "X-Requested-With"
  }
});