import { useCookies } from 'react-cookie';
import apiClient from "../http-common.js";

const getWallet = async (token) => {
  console.log("calling /wallet/get");
  return await apiClient.get(
    "/wallet/get",
    {
      headers: {
        'token': token
      }
    }
  );
};

const walletDeposit = async (token, amount) => {
  let response;
  try {
      response = await apiClient.post(
        "/wallet/deposit",
        {
          amount
        },
        {
          headers: {
            'token': token
          }
        }
      );
  } catch(error) {
      console.log("error:");
      console.log(error);
      if(error && error.response && error.response.data) {
          return error.response.data;
      }
      return error;
  }
  return { ...response.data, message: "ok" };
};

const walletWithdraw = async (token, amount) => {
  let response;
  try {
      response = await apiClient.post(
        "/wallet/withdraw",
        {
          amount
        },
        {
          headers: {
            'token': token
          }
        }
      );
  } catch(error) {
      console.log("error:");
      console.log(error);
      if(error && error.response && error.response.data) {
          return error.response.data;
      }
      return error;
  }
  return { ...response.data, message: "ok" };
};

const WalletService = {
  getWallet,
  walletDeposit,
  walletWithdraw,
}

export default WalletService;