import apiClient from "../http-common.js";

const addNotice = async (token, data) => {
  let response;
  try {
    response =  await apiClient.post(
      "/notice/add",
      data,
      {
        headers: {
          'token': token
        }
      }
    );
  } catch(error) {
      console.log("error:");
      console.log(error);
      if(error && error.response && error.response.data) {
          return error.response.data;
      }
      return error;
  }
  return { ...response.data, message: "ok" };
};

const getNotice = async (token, data) => {
  let response;
  try {
    response =  await apiClient.get(
      "/notice/get?userview=1&noticeId="+data.noticeId,
      {
        headers: {
          'token': token
        }
      }
    );
  } catch(error) {
      console.log("error:");
      console.log(error);
      if(error && error.response && error.response.data) {
          return error.response.data;
      }
      return error;
  }
  return { ...response.data, message: "ok" };
};

const listNotices = async (token, data) => {
  console.log("calling /notice/lists");
  return await apiClient.post(
    "/notice/list",
    data,
    {
      headers: {
        'token': token
      }
    }
  );
};

const NoticeService = {
  addNotice,
  getNotice,
  listNotices,
}

export default NoticeService;