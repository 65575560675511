import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import './footer.css';

export const Footer = () => {
    const { t } = useTranslation();
  
    return (
      <footer className="page-footer">
          <div className="container">
            <div className="row">
              <div className="col l2 m6">
                <h5 className="white-text">{t('footer_sect_1_title')}</h5>
                <p>
                  <NavLink to='/#'>{t('footer_sect_1_opt_1')}</NavLink>
                  <br />
                  <NavLink to='/#'>{t('footer_sect_1_opt_2')}</NavLink>
                  <br />
                  <NavLink to='/#'>{t('footer_sect_1_opt_3')}</NavLink>
                </p>
              </div>
              <div className="col l2 m6">
                <h5 className="white-text">{t('footer_sect_2_title')}</h5>
                <p>
                  <NavLink to='/agreement?type=privacy'>{t('footer_sect_2_opt_1')}</NavLink>
                  <br />
                  <NavLink to='/agreement?type=service'>{t('footer_sect_2_opt_2')}</NavLink>
                </p>
              </div>
              <div className="col l2 m6">
                <h5 className="white-text">{t('footer_sect_3_title')}</h5>
                <p>
                  <NavLink to='/#'>{t('footer_sect_3_opt_1')}</NavLink>
                  <br />
                  <NavLink to='/#'>{t('footer_sect_3_opt_2')}</NavLink>
                  <br />
                  <NavLink to='/#'>{t('footer_sect_3_opt_3')}</NavLink>
                </p>
              </div>
              <div className="col l3 m12">
                <h5 className="white-text">{t('footer_sect_4_title')}</h5>
                <p>{t('footer_sect_4_opt_1')}</p>
              </div>
              <div className="col l3 m12">
                <div className="row">
                  <div className="col l6 m12">
                    <p>{t('footer_contact_1')}</p>
                  </div>
                  <div className="col l6 m12">
                    <p>{t('footer_contact_2')}</p>
                  </div>
                  <div className="col l12 m12">
                    <h5>{t('footer_address')}</h5>
                  </div>
                  <div className="col l12 m12">
                    <h5>{t('footer_phone')}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <div className="container" style={{textAlign: "center"}}>
              © {(new Date()).getFullYear()} Copyright reserved | 
            <a href="#!">{ t('footer_authorization_1') }</a> | 
            <a href="#!">{ t('footer_authorization_2') }</a>
            </div>
          </div>
        </footer>
    );
}