import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import NoticeApi from '../../api/notice/index.js';

export const NoticePagination = (props) => {
    const { i18n, t } = useTranslation();
    const [cookies, setCookie] = useCookies(['auth']);
    const { filter, notices, setFilter, listNoticeData } = props;

    const getPagerListItems = () => {
        const items = [];
        for (let count = 0; count * notices.pageSize < notices.totalCount; count++) {
            items.push(
                <li className={`number ${notices.currentPage === count + 1 ? "active" : ""}`}>{count+1}</li>
            );
        }

        return items;
    }
  
    return ( 
        <div className="notice-pagination-box input">
            <div className="notice-pagination-elem is-background">
                <button type="button" disabled="disabled" className="btn-prev"><i className="tiny material-icons">keyboard_arrow_left</i></button>
                <ul  className="notice-pagination-elem-pager">
                    {getPagerListItems()}
                </ul>
                <button type="button" disabled="disabled" className="btn-next"><i className="tiny material-icons">keyboard_arrow_right</i></button>

                <span  className="notice-pagination-elem-jump">
                    前往
                    <div className="el-input notice-pagination-elem-editor is-in-pagination">
                        <input type="number" autoComplete="off" min="1" max="1" className="notice-pagination-elem-input" />
                    </div>
                    页
                </span>

                <span className="notice-pagination-elem-total">共 {notices.totalCount} 条</span>
            </div>
        </div>
    );
}