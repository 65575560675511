
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import DateUtils from '../../utils/date/index.js';

export const NoticeInfo = (props) => {
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(0);
    const [cookies, setCookie] = useCookies(['auth']);

    const { notice } = props;

    useEffect(() => {
        console.log("cookies.auth.userid");
        console.log(cookies.auth.userid);
        console.log("notice.publisherId");
        console.log(notice.publisherId);
    }, []);

    const getExpectIList = () => {
        const expectIList = [];

        for (const expect of notice.areas) {
            expectIList.push(<i>{expect}</i>);
        }

        return expectIList;
    }

    const getFanRequirement = () => {
        switch (notice.fansRequirement) {
            case "0-999":
                return "1千以下";
            case "1000-4999":
                return "1千-5千";
            case "5000-9999":
                return "5千-1万";
            case "10000-49999":
                return "1万-5万";
            case "50000-99999":
                return "5万-10万";
            case "100000+":
                return "10万以上";
            default:
                return "不限";
        }
    }

    return (
        <div className="notice-info-body flex-1">
            <div className="box base mb-24 flex">
                <div className="notice-info-cover">
                    <img src="/images/no_image_available.jpg" className="br-4" />
                </div>
                <div className="notice-info-intro flex-1">
                    <div className="row1 flex flex-jc-sp flex-ai-c">
                        <p className="flex-1">
                            <span className="notice-info-title fs-20">{notice.title}</span>
                        </p>
                    </div>
                    <div className="row3 flex flex-jc-sp flex-ai-c">
                        <span className="flex-1 notice-type">{notice.type === 'store' ? "探店" : "产品测评"}</span>
                        <span className="notice-time-release fs-12">{DateUtils.formatDate(new Date(notice.createDate))}</span>
                    </div>
                    <div className="row4 flex">
                        <span>
                            发布平台
                            <i>
                                <img width="18" height="18" src={`/images/medias/icon_${notice.platform}_rect.png`} className="br-4" />
                            </i>
                        </span>
                        <span>招募人数<i>{notice.recruitmentNumber}</i></span>
                        <span>截止日期<i>{DateUtils.formatDate(new Date(notice.deadline))}</i></span>
                    </div>
                    <div className="row4">
                        <span>粉丝要求<i>{getFanRequirement()}</i></span>
                        <span>粉丝数量<i>{notice.requireFansRequirement ? "必须达到" : "只做参考"}</i></span>
                        <span>品牌名称<i>{notice.brandName}</i></span>
                    </div>
                    <div className="row5 flex">
                        <span>达人领域</span>
                        <p className="flex-1">
                            {getExpectIList()}
                        </p>
                    </div>
                    <div className="row6 flex flex-ai-c flex-jc-sp">
                        <div className="flex-1">
                            {cookies.auth.userid === notice.publisherId ?
                            <span className="notice-info-botton br-4 cr-p">修改通告</span> :
                            <span className="notice-info-botton br-4 cr-p">立即报名</span>}
                        </div>
                        <div data-v-affb38b6="" className="notice-report">
                            <span data-v-affb38b6="" className="otice-report-botton cr-p"><i class="tiny material-icons">warning</i>举报</span>
                            {/* <div data-v-affb38b6="" className="el-dialog__wrapper" style={{display: "none"}}>
                                <div role="dialog" aria-modal="true" aria-label="举报任务" className="el-dialog el-dialog--center dialog-report" style={{marginTop: "15vh", width: "600px"}}>
                                    <div className="el-dialog__header">
                                        <span className="el-dialog__title">举报任务</span>
                                        <button type="button" aria-label="Close" className="el-dialog__headerbtn">
                                            <i className="el-dialog__close el-icon el-icon-close"></i>
                                        </button>
                                    </div>
                                    <div className="el-dialog__footer">
                                        <span data-v-affb38b6="" className="dialog-footer">
                                            <button data-v-affb38b6="" type="button" className="el-button el-button--default">
                                                <span>取 消</span>
                                            </button>
                                            <button data-v-affb38b6="" type="button" className="el-button el-button--primary">
                                                <span>确 定</span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="box release mb-24">
                <div className="notice-publisher-header flex flex-jc-sb">
                    <span className="title">发布人信息</span>
                    <span className="more cr-p">查看更多TA的发布<i class="tiny material-icons">keyboard_arrow_right</i></span>
                </div>
                <div className="notice-publisher-body">
                    <div className="issuer flex">
                        <div className="profile-photo">
                            <img src="/images/default_avatar_new_1.png" width="60" height="60" className="br-50" />
                        </div>
                        <div className="info flex flex-fd-c flex-jc-sb flex-1">
                            <div className="contact">
                                <span className="nickname fs-16">{notice.publisher.username}</span>
                                <span>报名审核通过后，双向可联系</span>
                            </div>
                            <div className="evaluation">暂无评价</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box detail mb-24">
                <div className="notice-detail-header">
                    <span className="title">任务详情</span>
                </div>
                <div className="notice-detail-body">
                    <pre>{notice.description}</pre>
                </div>
            </div>
            <div className="box pic">
                <div className="notice-picture-header">
                    <span className="title">任务图片</span>
                </div>
                <div className="notice-picture-body ta-c">
                    <span className="mb-24">
                        <img src="/images/no_image_available.jpg" className="img" />
                    </span>
                    <span className="mb-24">
                        <img src="/images/no_image_available.jpg" className="img" />
                    </span>
                    <span className="mb-24">
                        <img src="/images/no_image_available.jpg" className="img" />
                    </span>
                    <span className="mb-24">
                        <img src="/images/no_image_available.jpg" className="img" />
                    </span>
                </div>
            </div>
        </div>
    )
}