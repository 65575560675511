import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { LANGUAGES } from '../constants/index.js';
import { Login } from './Login.js';

import './header.css';

export const Header = () => {
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(0);
    const [cookies, setCookie] = useCookies(['auth']);
    const isActive = ({ isActive }) => `link ${isActive ? 'active' : ''}`;

    const onChangeLang = () => {
        if (currentLanguage < LANGUAGES.length - 1) {
          const lang_code = LANGUAGES[currentLanguage+1].code;
          i18n.changeLanguage(lang_code);

          setCurrentLanguage(currentLanguage + 1);
        } else {
          const lang_code = LANGUAGES[0].code;
          i18n.changeLanguage(lang_code);

          setCurrentLanguage(0);
        }
    }
    const [isClickedSubmenu, setIsClickedSubmenu] = useState(false);
    const handleClickMenuCollapseButton = () => {
      setIsClickedSubmenu(!isClickedSubmenu);
    };
    const [displayLogin, setDisplayLogin] = useState(false);
    const handleClickLoginButton = (yesOrNot) => {
      setDisplayLogin(yesOrNot);
    };

  
    return (
      <header id="navbar" className="navbar-fixed scrollspy">
        <nav className="grey darken-2">
          <div className="nav-wrapper container">
            <div className="container">
              <NavLink className={`brand-logo ${isActive}`} to='/'>{t('menu_home')}</NavLink>
            </div>
            
            <a href="#" data-activates="mobile-demo" className="button-collapse" onClick={() => handleClickMenuCollapseButton()}>
              <i className="tiny material-icons">dehaze</i>
            </a>

            {(isClickedSubmenu) && (
              <div>
                <div className="menu-back" onClick={() => handleClickMenuCollapseButton()}></div>
                <ul className="right side-nav" id="mobile-demo">
                  {cookies.auth ? 
                  <li onClick={() => handleClickMenuCollapseButton()}><NavLink className={isActive} to='/service'>{t('menu_service')}</NavLink></li> :
                  <li onClick={(() => handleClickMenuCollapseButton()) && (() => handleClickLoginButton(true))}><NavLink className={isActive} to='#'>{t('menu_login')}</NavLink></li>
                  }
                  <li id="side-nav-lang"><a href='#' onClick={() => onChangeLang()}>{LANGUAGES[currentLanguage].label}</a></li>
                </ul>
              </div>
            )}

            <ul className="right hide-on-med-and-down">
              {cookies.auth ? 
              <li><NavLink className={isActive} to='/service'>{t('menu_service')}</NavLink></li> :
              <li><NavLink className={isActive} to='#' onClick={() => handleClickLoginButton(true)}>{t('menu_login')}</NavLink></li>
              }
              <li><NavLink className={isActive} to='#' onClick={() => onChangeLang()}>{LANGUAGES[currentLanguage].label}</NavLink></li>
            </ul>
          </div>
        </nav>
        {(displayLogin) && (<Login setDisplayLogin={setDisplayLogin} />)}
      </header>
    );
}