import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { ConfigProvider, Space } from 'antd';
import { Agreement } from './pages/Agreement/Agreement.js';
import { Blog } from './pages/Blog.js';
import { Home } from './pages/Home/Home.js';
import { Service } from './pages/Service/Service.js';
import { ServiceBalance } from './pages/ServiceBalance/ServiceBalance.js';
import { ServiceKol } from './pages/ServiceKol/ServiceKol.js';
import { ServiceNotice } from './pages/ServiceNotice/ServiceNotice.js';
import { ServiceNoticeInfo } from './pages/ServiceNoticeInfo/ServiceNoticeInfo.js';
import { UserProfile } from './pages/UserProfile/UserProfile.js';
import Popupbox from './components/Popupbox.js';

import { Suspense } from 'react';

const App = () => {

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token，影响范围大
          colorPrimary: '#00b96b',
          borderRadius: 2,

          // 派生变量，影响范围小
          colorBgContainer: '#f6ffed',
        },
      }}
    >
      <Space>
        <Suspense fallback='loading'>
          <BrowserRouter>
            <CookiesProvider defaultSetOptions={{ path: '/' }}>
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/agreement' element={<Agreement />} />
                <Route path='/blog' element={<Blog />} />
                <Route path='/service' element={<Service />} />
                <Route path='/service_balance' element={<ServiceBalance />} />
                <Route path='/service_kol' element={<ServiceKol />} />
                <Route path='/service_notice' element={<ServiceNotice />} />
                <Route path='/service_notice_info' element={<ServiceNoticeInfo />} />
                <Route path='/user_profile' element={<UserProfile />} />
                <Route path='/*' element={<Navigate to='/' />} />
              </Routes>
              <Popupbox />
            </CookiesProvider>
          </BrowserRouter>
        </Suspense>
      </Space>
    </ConfigProvider>
  )
}
export default App;

