import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import NoticeApi from '../../api/notice/index.js';

export const NoticeFilter = (props) => {
    const { i18n, t } = useTranslation();
    const [cookies, setCookie] = useCookies(['auth']);
    const { filter, setFilter, handleResetFilter, listNoticeData } = props;

    const expectGroup = [
        "园艺","舞蹈","剧情搞笑","颜值","旅行","汽车","商业财经","摄影","游戏","教育",
        "生活记录","兴趣爱好","房产","音乐","才艺技能","生活","二次元","图文控",
        "影视娱乐","文化艺术","萌娃","三农","美妆个护","食饮生鲜","家居生活","服饰箱包",
        "母婴亲子","3C电器","运动健身","萌宠","其他"
    ]

    const handlePlatformFilterChange = (platform) => {
        if (filter.platform === platform) {
            setFilter({...filter, platforms: ""});
            listNoticeData({...filter, platforms: ""});
        } else {
            setFilter({...filter, platform: platform});
            listNoticeData({...filter, platform: platform});
        }
    }

    const handleTypeFilterChange = async (type) => {
        await setFilter({...filter, type});
        listNoticeData({...filter, type});
    }
    
    const handleRerwardFromFilterChange = async (event) => {
        await setFilter({...filter, rewardPerRecruitmentFrom: parseInt(event.target.value, 10)});
        // listNoticeData({...filter, rewardPerRecruitmentFrom: parseInt(event.target.value, 10)});
    }

    const handleRerwardToFilterChange = async (event) => {
        await setFilter({...filter, rewardPerRecruitmentTo: parseInt(event.target.value, 10)});
        // listNoticeData({...filter, rewardPerRecruitmentTo: parseInt(event.target.value, 10)});
    }

    const handleDeadlineFromFilterChange = async (event) => {
        console.log(event.target.value);
        await setFilter({...filter, deadlineFrom: event.target.value});
        // listNoticeData({...filter, rewardPerRecruitmentFrom: parseInt(event.target.value, 10)});
    }

    const handleDeadlineToFilterChange = async (event) => {
        console.log(event.target.value);
        await setFilter({...filter, deadlineTo: event.target.value});
        // listNoticeData({...filter, rewardPerRecruitmentFrom: parseInt(event.target.value, 10)});
    }

    const handleFansReqFilterChange = async (fansRequirement) => {
        await setFilter({...filter, fansRequirement});
        listNoticeData({...filter, fansRequirement});
    }

    function removeElement(array, elementToRemove) {
        array.forEach((item, index) => {
            if (item === elementToRemove) {
                array.splice(index, 1);
            }
        });
        return array;
    }

    const handleExpectFilterChange = async (expect) => {
        console.log(expect);
        if (filter.areas.includes(expect)) {
            removeElement(filter.areas, expect);
            // listNoticeData({...filter, areas: filter.areas});
        } else {
            await setFilter({...filter, areas: [...filter.areas, expect]});
            // listNoticeData({...filter, areas: [...filter.areas, expect]});
        }
    }

    const generateExpectCheckboxGroup = () => {
        const items = [];

        for (const expect of expectGroup) {
            items.push(
                <label className="checkbox-container">{expect}
                    <input type="checkbox" onClick={() => handleExpectFilterChange(expect)} className={`${filter.areas.includes({expect}) ? "checked" : ""}`} />
                    <span className="checkmark"></span>
                </label>
            );
        }

        return items;
    }
  
    return ( 
        <div className="notice-filter-box">
            <div className="notice-filter-1">
                <div className="notice-filter-platform ft-l">
                    <ul className="clear" style={{listStyle: "none"}}>
                        <li className={`ft-l cr-p ${filter.platform === "bili"? "active" : ""}`} onClick={() => handlePlatformFilterChange("bili")}>
                            <img src="/images/medias/icon_bili_rect.png" width="30" height="30" className="ft-l" />
                            <span className="ft-l">哔哩哔哩</span>
                        </li>
                        <li className={`ft-l cr-p ${filter.platform === "douyin"? "active" : ""}`} onClick={() => handlePlatformFilterChange("douyin")}>
                            <img src="/images/medias/icon_douyin_rect.png" width="30" height="30" className="ft-l" />
                            <span className="ft-l">抖音</span>
                        </li>
                        <li className={`ft-l cr-p ${filter.platform === "ks"? "active" : ""}`} onClick={() => handlePlatformFilterChange("ks")}>
                            <img src="/images/medias/icon_ks_rect.png" width="30" height="30" className="ft-l" />
                            <span className="ft-l">快手</span>
                        </li>
                        <li className={`ft-l cr-p ${filter.platform === "redbook"? "active" : ""}`} onClick={() => handlePlatformFilterChange("redbook")}>
                            <img src="/images/medias/icon_redbook_rect.png" width="30" height="30" className="ft-l" />
                            <span className="ft-l">小红书</span>
                        </li>
                        <li className={`ft-l cr-p ${filter.platform === "weibo"? "active" : ""}`} onClick={() => handlePlatformFilterChange("weibo")}>
                            <img src="/images/medias/icon_weibo_rect.png" width="30" height="30" className="ft-l" />
                            <span className="ft-l">微博</span>
                        </li>
                        <li className={`ft-l cr-p ${filter.platform === "weixin"? "active" : ""}`} onClick={() => handlePlatformFilterChange("weixin")}>
                            <img src="/images/medias/icon_weixin_rect.png" width="30" height="30" className="ft-l" />
                            <span className="ft-l">微信</span>
                        </li>
                        <li className={`ft-l cr-p ${filter.platform === "zhihu"? "active" : ""}`} onClick={() => handlePlatformFilterChange("zhihu")}>
                            <img src="/images/medias/icon_zhihu_rect.png" width="30" height="30" className="ft-l" />
                            <span className="ft-l">知乎</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="notice-filter-2">
                <div className="tit">任务类型</div>
                <div className="ofh">
                    <span className={`notice-filter-2-item ${filter.type === "all"? "notice-filter-2-item-active" : ""}`} onClick={() => handleTypeFilterChange('all')}>全部</span>
                    <span className={`notice-filter-2-item ${filter.type === "store"? "notice-filter-2-item-active" : ""}`} onClick={() => handleTypeFilterChange('store')}>探店</span>
                    <span className={`notice-filter-2-item ${filter.type === "product"? "notice-filter-2-item-active" : ""}`} onClick={() => handleTypeFilterChange('product')}>产品测评</span>
                </div>
            </div>
            
            <div className="collapse" id="filterCollapse">
                <div className="card-body" style={{padding: 0, border: 0}}>
                <div className="notice-filter-3 search ft-r">
                    <div className="tit">报酬</div>
                    <div style={{display: "flex"}}>
                        <input type="number" autocomplete="off" placeholder="最少报酬" className="filter-input-box" onChange={handleRerwardFromFilterChange} />
                        <input type="number" autocomplete="off" placeholder="最大报酬" className="filter-input-box" onChange={handleRerwardToFilterChange} />
                    </div>
                </div>
                <div className="notice-filter-3 search ft-r">
                    <div className="tit">截止日期</div>
                    <div style={{display: "flex"}}>
                        <input type="date" autocomplete="off" placeholder="最早截止日期" className="filter-input-box" onChange={handleDeadlineFromFilterChange} />
                        <input type="date" autocomplete="off" placeholder="最晚截止日期" className="filter-input-box" onChange={handleDeadlineToFilterChange} />
                    </div>
                </div>
                <div className="notice-filter-2">
                    <div className="tit">粉丝数量</div>
                    <div className="ofh">
                        <span className={`notice-filter-2-item ${filter.fansRequirement === "0+"? "notice-filter-2-item-active" : ""}`} onClick={() => handleFansReqFilterChange("0+")}>不限</span>
                        <span className={`notice-filter-2-item ${filter.fansRequirement === "0-999"? "notice-filter-2-item-active" : ""}`} onClick={() => handleFansReqFilterChange("0-999")}>1千以下</span>
                        <span className={`notice-filter-2-item ${filter.fansRequirement === "1000-4999"? "notice-filter-2-item-active" : ""}`} onClick={() => handleFansReqFilterChange("1000-4999")}>1千-5千</span>
                        <span className={`notice-filter-2-item ${filter.fansRequirement === "5000-9999"? "notice-filter-2-item-active" : ""}`} onClick={() => handleFansReqFilterChange("5000-9999")}>5千-1万</span>
                        <span className={`notice-filter-2-item ${filter.fansRequirement === "10000-49999"? "notice-filter-2-item-active" : ""}`} onClick={() => handleFansReqFilterChange("10000-49999")}>1万-5万</span>
                        <span className={`notice-filter-2-item ${filter.fansRequirement === "50000-99999"? "notice-filter-2-item-active" : ""}`} onClick={() => handleFansReqFilterChange("50000-99999")}>5万-10万</span>
                        <span className={`notice-filter-2-item ${filter.fansRequirement === "100000+"? "notice-filter-2-item-active" : ""}`} onClick={() => handleFansReqFilterChange("100000+")}>10万以上</span>
                    </div>
                </div>
                <div class="notice-filter-2 search ft-r">
                    <div className="tit">达人领域</div>
                    <div class="notice-expect-group">
                        {generateExpectCheckboxGroup()}
                    </div>
                </div>
                </div>
            </div>
            <div className="filter-collapse-btnbox">
                <button className="filter-collapse-button" data-toggle="collapse" data-target="#filterCollapse" aria-expanded="false" aria-controls="filterCollapse">
                    <i class="material-icons">expand_more</i>
                </button>
            </div>
            <div className="notice-filter-button-group">
                <button className="btn btn-light" onClick={() => handleResetFilter()} style={{marginRight: "10px"}}>Reset</button>
                <button className="btn btn-primary" onClick={() => listNoticeData(filter)}>Search</button>
            </div>
        </div>
    );
}