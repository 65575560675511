import { useTranslation } from 'react-i18next';

export const Section_2 = () => {

    const { t } = useTranslation()

    return (
        <section className="section--center container">
                <div className="row" style={{marginBottom: 0}}>
                    <div className="col-sm-12 col-md-6">
                        <header className="section__play-btn mdl-cell mdl-cell--3-col-desktop mdl-cell--2-col-tablet mdl-cell--4-col-phone mdl-color--teal-100 mdl-color-text--white">
                            <img src="/images/home_section_2.png" crossOrigin="anonymous" alt="placeholder" style={{width: "100%", height: "100%", objectFit: "contain", overflow: "hidden"}} />
                        </header>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="card-content">
                            <h4 className="post-title">{t('home_section2_title')}</h4>
                            <h5 className="post-subtitle">{t('home_section2_subtitle')}</h5>
                            <p>
                                <span className='post-option' /> {t('home_section2_content_1')}
                                <br />
                                <span className='post-option' /> {t('home_section2_content_2')}
                            </p>
                        </div>
                    </div>
            </div>                
        </section>
    )
}