import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export const RedPromotion = () => {
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(0);
    const [cookies, setCookie] = useCookies(['auth']);
  
    return (
        <div className="promotion">
            <div className="module-name">
                <img className="icon" src="/images/owner_home_title_icon.png" alt="" />
                <span className="promotion-title">红人推广</span>
                <span className="promotion-subtitle">(有<span style={{color: "#FF405C"}}>1</span>位红人在线，支持红人在线直联)</span>
            </div>

            <div style={{ overflowX: "auto" }}>
                <ul className="promote-type">
                    <li>
                        <img className="plat-bg" src="/images/owner_home_plat_bg_redbook.png" alt="" />
                        <a href="#">
                            <span className="plat-icon-box">
                                <img className="plat-icon" src="/images/medias/icon_weibo_rect.png" alt="" />
                            </span>
                            <p>微博推广 <i class="tiny material-icons">chevron_right</i></p>
                        </a>
                    </li>
                    <li className="yd_1-box">
                        <img className="plat-bg" src="/images/owner_home_plat_bg_redbook.png" alt="" />
                        <a className="wx-icon-a" href="#">
                            <span className="plat-icon-box">
                                <img className="plat-icon" src="/images/medias/icon_weixin_rect.png" alt="" />
                            </span>
                            <p>微信推广 <i class="tiny material-icons">chevron_right</i></p>
                        </a>
                    </li>
                    <li>
                        <img className="plat-bg" src="/images/owner_home_plat_bg_redbook.png" alt="" />
                        <a href="#">
                            <span className="plat-icon-box">
                                <img className="plat-icon" src="/images/medias/icon_douyin_rect.png" alt="" />
                            </span>
                            <p>抖音推广 <i class="tiny material-icons">chevron_right</i></p>
                        </a>
                    </li>
                    <li>
                        <img className="plat-bg" src="/images/owner_home_plat_bg_redbook.png" alt="" />
                        <a href="#">
                            <span className="plat-icon-box">
                                <img className="plat-icon" src="/images/medias/icon_redbook_rect.png" alt="" />
                            </span>
                            <p>小红书推广 <i class="tiny material-icons">chevron_right</i></p>
                        </a>
                    </li>
                    <li>
                        <img className="plat-bg" src="/images/owner_home_plat_bg_redbook.png" alt="" />
                        <a href="#">
                            <span className="plat-icon-box">
                                <img className="plat-icon" src="/images/medias/icon_ks_rect.png" alt="" />
                            </span>
                            <p>快手推广 <i class="tiny material-icons">chevron_right</i></p>
                        </a>
                    </li>
                    <li>
                        <img className="plat-bg" src="/images/owner_home_plat_bg_redbook.png" alt="" />
                        <a href="#">
                            <span className="plat-icon-box">
                                <img className="plat-icon" src="/images/medias/icon_bili_rect.png" alt="" />
                            </span>
                            <p>bilibili推广 <i class="tiny material-icons">chevron_right</i></p>
                        </a>
                    </li>
                    <li>
                        <img className="plat-bg" src="/images/owner_home_plat_bg_redbook.png" alt="" />
                        <a href="#">
                            <span className="plat-icon-box">
                                <img className="plat-icon" src="/images/medias/icon_zhihu_rect.png" alt="" />
                            </span>
                            <p>知乎推广</p>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}