
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';import { useTranslation } from 'react-i18next';
import { AddNotice } from './AddNotice.js';
import { NoticeFilter } from './NoticeFilter.js';
import { NoticeList } from './NoticeList.js';
import { NoticePagination } from './NoticePagination.js';
import { ServiceHeader } from '../../components/ServiceHeader.js';
import { ServiceSidebar } from '../../components/ServiceSidebar.js';
import { Footer } from '../../components/Footer.js';

import NoticeApi from '../../api/notice/index.js';
import DateUtils from '../../utils/date/index.js';

import './serviceNotice.css';

export const ServiceNotice = () => {
    const { i18n, t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(0);
    const [cookies, setCookie] = useCookies(['auth']);
    const [showAddTable, setShowAddTable] = useState(false);

    const defaultFilter = {
        publisherId: "",
        type: "all", // all, store, product
        platform: "", // bili, douyin, ks, redbook, weibo, weixin, zhihu
        rewardPerRecruitmentFrom: null,
        rewardPerRecruitmentTo: null,
        deadlineFrom: DateUtils.formatDate(new Date()), //
        deadlineTo: null,
        fansRequirement: "0+", // 0+, 0-999, 1000-4999, 5000-9999, 10000-49999, 50000-99999, 100000+
        areas: [], // 园艺,舞蹈,剧情搞笑,颜值,旅行,汽车,商业财经,摄影,游戏,教育,生活记录,兴趣爱好,房产,音乐,才艺技能,生活,二次元,图文控,影视娱乐,文化艺术,萌娃,三农,美妆个护,食饮生鲜,家居生活,服饰箱包,母婴亲子,3C电器,运动健身,萌宠,其他

        status: "active",
        pageSize: 18,
        page: 1
    };

    const [filter, setFilter] = useState({...defaultFilter});
    const [notices, setNotices] = useState({
        currentPage: 1,
        pageSize: 20,
        totalCount: 0,
        data: []
    });

    const handleResetFilter = async () => {
        setFilter({...defaultFilter});
        window.clearTimeout(1000);
        listNoticeData({...defaultFilter});
    }

    async function listNoticeData(listFilter) {
        const serchParams = {
            status: "active",
            pageSize: 20,
            page: 1,
        };
        if (listFilter.publisherId !== "") {
            serchParams.publisherId = listFilter.publisherId;
        }
        if (listFilter.type !== "all") {
            serchParams.type = listFilter.type;
        }
        if (listFilter.platform != "") {
            serchParams.platform = listFilter.platform;
        }
        if (listFilter.rewardPerRecruitmentFrom !== null) {
            serchParams.rewardPerRecruitmentFrom = listFilter.rewardPerRecruitmentFrom;
        }
        if (listFilter.rewardPerRecruitmentTo !== null) {
            serchParams.rewardPerRecruitmentTo = listFilter.rewardPerRecruitmentTo;
        }
        if (listFilter.deadlineFrom !== null) {
            serchParams.deadlineFrom = listFilter.deadlineFrom;
        }
        if (listFilter.deadlineTo !== null) {
            serchParams.deadlineTo = listFilter.deadlineTo;
        }
        if (listFilter.fansRequirement !== "0+") {
            serchParams.fansRequirement = listFilter.fansRequirement;
        }
        if (listFilter.areas.length > 0) {
            serchParams.areas = listFilter.areas;
        }
        const response = await NoticeApi.listNotices(
            cookies.auth.token,
            serchParams
        );
        console.log(response.data);
        setNotices(response.data);
    }

    useEffect(() => {
        listNoticeData(filter);
    }, []);

    return (
        <main style={{backgroundColor: "lightgrey"}}>
            <ServiceHeader />
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12 col-md-2'>
                        <ServiceSidebar />
                    </div>
                    <div className='col-sm-12 col-md-10' id='noticeboard'>
                        {!showAddTable && (
                        <div>
                            <NoticeFilter filter={filter} setFilter={setFilter} handleResetFilter={handleResetFilter} listNoticeData={listNoticeData} />
                            <NoticeList notices={notices} />
                            {notices.data.length > 0 && <NoticePagination filter={filter} notices={notices} setFilter={setFilter} listNoticeData={listNoticeData} />}
                            <a class="new-notice-button" onClick={() => setShowAddTable(true)}>
                                <i class="medium material-icons green" style={{borderRadius: "50%"}}>add_circle_outline</i>
                            </a>
                        </div>
                        )}
                        {showAddTable && <AddNotice handleResetFilter={handleResetFilter} listNoticeData={listNoticeData} setShowAddTable={setShowAddTable} />}
                    </div>
                </div>
            </div>
            <Footer />
        </main>
    )
}