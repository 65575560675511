import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import NoticeApi from '../../api/notice/index.js';
import DateUtils from '../../utils/date/index.js';

export const AddNotice = (props) => {
    const { i18n, t } = useTranslation();
    const [cookies, setCookie] = useCookies(['auth']);
    const [errorMessage, setErrorMessage] = useCookies(['error']);
    const { handleResetFilter, setShowAddTable } = props;

    const defaultNewNotice = {
        type: "store", // store, product
        brandName: "", // !
        address: "", // !
        title: "", // !
        description: "", // !
        platform: "bili", // bili, douyin, ks, redbook, weibo, weixin, zhihu
        recruitmentNumber: 0,
        rewardPerRecruitment: 0,
        deadline: DateUtils.formatDate(new Date()),
        fansRequirement: "0+", // 0+, 0-999, 1000-4999, 5000-9999, 10000-49999, 50000-99999, 100000+
        requireFansRequirement: false,
        areas: [], // 园艺,舞蹈,剧情搞笑,颜值,旅行,汽车,商业财经,摄影,游戏,教育,生活记录,兴趣爱好,房产,音乐,才艺技能,生活,二次元,图文控,影视娱乐,文化艺术,萌娃,三农,美妆个护,食饮生鲜,家居生活,服饰箱包,母婴亲子,3C电器,运动健身,萌宠,其他
        requireArea: false,
        images: [],
    };

    const [newNotice, setNewNotice] = useState({...defaultNewNotice});

    const handleAddNotice = async () => {
        if (newNotice.title.length < 1) {
            setErrorMessage(
                'error',
                { popupMessage: '請輸入通告标题' },
                { path: '/', maxAge: 5 }
            );
            return;
        }
        if (newNotice.description.length < 1) {
            setErrorMessage(
                'error',
                { popupMessage: '請輸入需求描述' },
                { path: '/', maxAge: 5 }
            );
            return;
        }

        console.log("handleAddNotice");
        console.log(newNotice);
        const result = await NoticeApi.addNotice(
            cookies.auth.token,
            newNotice
        );
        console.log("result");
        console.log(result);
        if (result.message === "ok") {
            handleResetFilter();
            setShowAddTable(false);
        } else {
            setErrorMessage(
                'error',
                { popupMessage: typeof result.message === "string" ? result.message : "login error" },
                { path: '/', maxAge: 5 }
            );
        }
    }

    const expectGroup = [
        "园艺","舞蹈","剧情搞笑","颜值","旅行","汽车","商业财经","摄影","游戏","教育",
        "生活记录","兴趣爱好","房产","音乐","才艺技能","生活","二次元","图文控",
        "影视娱乐","文化艺术","萌娃","三农","美妆个护","食饮生鲜","家居生活","服饰箱包",
        "母婴亲子","3C电器","运动健身","萌宠","其他"
    ]

    const handlePlatformNewNoticeChange = (platform) => {
        setNewNotice({...newNotice, platform: platform});
    }

    const handleTypeNewNoticeChange = async (type) => {
        setNewNotice({...newNotice, type});
    }

    const handleBrandNameNewNoticeChange = async (event) => {
        setNewNotice({...newNotice, brandName: event.target.value});
    }

    const handleAddressNewNoticeChange = async (event) => {
        setNewNotice({...newNotice, address: event.target.value});
    }

    const handleTitleNewNoticeChange = async (event) => {
        setNewNotice({...newNotice, title: event.target.value});
    }

    const handleDescriptionNoticeChange = async (event) => {
        setNewNotice({...newNotice, description: event.target.value});
    }

    
    const handleRecruitmentNumberNewNoticeChange = async (event) => {
        if (parseInt(event.target.value, 10) < 0) {
            event.target.value = '0';
        }
        if (parseInt(event.target.value, 10) > 200) {
            event.target.value = '200';
        }
        setNewNotice({...newNotice, recruitmentNumber: parseInt(event.target.value, 10)});
    }
    
    const handleRerwardNewNoticeChange = async (event) => {
        if (parseInt(event.target.value, 10) < 0) {
            event.target.value = '0';
        }
        setNewNotice({...newNotice, rewardPerRecruitment: parseInt(event.target.value, 10)});
    }

    const handleDeadlineNewNoticeChange = async (event) => {
        console.log(event.target.value);
        setNewNotice({...newNotice, deadline: event.target.value});
    }

    const handleReqFansReqNewNoticeChange = async () => {
        setNewNotice({...newNotice, requireFansRequirement: !newNotice.requireFansRequirement});
    }

    const handleFansReqNewNoticeChange = async (fansRequirement) => {
        if (fansRequirement === "0+") {
            setNewNotice({...newNotice, fansRequirement, requireFansRequirement: false});
        } else {
            setNewNotice({...newNotice, fansRequirement});
        }
    }

    function removeElement(array, elementToRemove) {
        array.forEach((item, index) => {
            if (item === elementToRemove) {
                array.splice(index, 1);
            }
        });
        return array;
    }

    const handleExpectNewNoticeChange = async (expect) => {
        if (newNotice.areas.includes(expect)) {
            const tempAreas = [...newNotice.areas];
            removeElement(tempAreas, expect);
            
            if (tempAreas.length === 0) {
                setNewNotice({...newNotice, areas: [], requireArea: false});
            } else {
                setNewNotice({...newNotice, areas: [...tempAreas]});
            }
        } else {
            setNewNotice({...newNotice, areas: [...newNotice.areas, expect]});
        }
    }

    const handleReqAreaNewNoticeChange = async () => {
        setNewNotice({...newNotice, requireArea: !newNotice.requireArea});
    }

    const generateExpectCheckboxGroup = () => {
        const items = [];

        for (const expect of expectGroup) {
            items.push(
                <label className="checkbox-container">{expect}
                    <input type="checkbox" onClick={() => handleExpectNewNoticeChange(expect)} className={`${newNotice.areas.includes({expect}) ? "checked" : ""}`} />
                    <span className="checkmark"></span>
                </label>
            );
        }

        return items;
    }
  
    return ( 
        <div className="notice-newNotice-box">
            <h1 className='text-center'>发布通告</h1>
            <div className="notice-newNotice-1">
                <div className="tit">发布平台</div>
                <div className="notice-newNotice-platform ft-l">
                    <ul className="clear" style={{listStyle: "none"}}>
                        <li className={`ft-l cr-p ${newNotice.platform === "bili"? "active" : ""}`} onClick={() => handlePlatformNewNoticeChange("bili")}>
                            <img src="/images/medias/icon_bili_rect.png" width="30" height="30" className="ft-l" />
                            <span className="ft-l">哔哩哔哩</span>
                        </li>
                        <li className={`ft-l cr-p ${newNotice.platform === "douyin"? "active" : ""}`} onClick={() => handlePlatformNewNoticeChange("douyin")}>
                            <img src="/images/medias/icon_douyin_rect.png" width="30" height="30" className="ft-l" />
                            <span className="ft-l">抖音</span>
                        </li>
                        <li className={`ft-l cr-p ${newNotice.platform === "ks"? "active" : ""}`} onClick={() => handlePlatformNewNoticeChange("ks")}>
                            <img src="/images/medias/icon_ks_rect.png" width="30" height="30" className="ft-l" />
                            <span className="ft-l">快手</span>
                        </li>
                        <li className={`ft-l cr-p ${newNotice.platform === "redbook"? "active" : ""}`} onClick={() => handlePlatformNewNoticeChange("redbook")}>
                            <img src="/images/medias/icon_redbook_rect.png" width="30" height="30" className="ft-l" />
                            <span className="ft-l">小红书</span>
                        </li>
                        <li className={`ft-l cr-p ${newNotice.platform === "weibo"? "active" : ""}`} onClick={() => handlePlatformNewNoticeChange("weibo")}>
                            <img src="/images/medias/icon_weibo_rect.png" width="30" height="30" className="ft-l" />
                            <span className="ft-l">微博</span>
                        </li>
                        <li className={`ft-l cr-p ${newNotice.platform === "weixin"? "active" : ""}`} onClick={() => handlePlatformNewNoticeChange("weixin")}>
                            <img src="/images/medias/icon_weixin_rect.png" width="30" height="30" className="ft-l" />
                            <span className="ft-l">微信</span>
                        </li>
                        <li className={`ft-l cr-p ${newNotice.platform === "zhihu"? "active" : ""}`} onClick={() => handlePlatformNewNoticeChange("zhihu")}>
                            <img src="/images/medias/icon_zhihu_rect.png" width="30" height="30" className="ft-l" />
                            <span className="ft-l">知乎</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="notice-newNotice-4">
                <div className="tit">任务类型</div>
                <div className="ofh">
                    <span className={`notice-newNotice-2-item ${newNotice.type === "store"? "notice-newNotice-2-item-active" : ""}`} onClick={() => handleTypeNewNoticeChange('store')}>探店</span>
                    <span className={`notice-newNotice-2-item ${newNotice.type === "product"? "notice-newNotice-2-item-active" : ""}`} onClick={() => handleTypeNewNoticeChange('product')}>产品测评</span>
                </div>
            </div>
            {
            newNotice.type === 'store' &&
            <div className="notice-newNotice-5 search ft-r">
                <div className="tit">地址</div>
                <div style={{display: "flex"}}>
                    <input type="text" autocomplete="off" placeholder="地址" className="newNotice-input-box" value={newNotice.address} onChange={handleAddressNewNoticeChange} />
                </div>
            </div>
            }
            <div className="notice-newNotice-5 search ft-r">
                <div className="tit">品牌方</div>
                <div style={{display: "flex"}}>
                    <input type="text" autocomplete="off" placeholder="品牌方" className="newNotice-input-box" value={newNotice.brandName} onChange={handleBrandNameNewNoticeChange} />
                </div>
            </div>
            <div className="notice-newNotice-5 search ft-r">
                <div className="tit">通告标题</div>
                <div style={{display: "flex"}}>
                    <input type="text" autocomplete="off" placeholder="通告标题" className="newNotice-input-box" value={newNotice.title} onChange={handleTitleNewNoticeChange} />
                </div>
            </div>
            <div className="notice-newNotice-5 search ft-r">
                <div className="tit">需求描述</div>
                <div style={{display: "flex"}}>
                    <textarea type="textarea" autocomplete="off" placeholder="需求描述" className="newNotice-input-box" value={newNotice.description} onChange={handleDescriptionNoticeChange} />
                </div>
            </div>
            <div className="notice-newNotice-3 search ft-r">
                <div className="tit">招募人数</div>
                <div style={{display: "flex"}}>
                    <input type="number" autocomplete="off" placeholder="招募人数" className="newNotice-input-box" value={newNotice.recruitmentNumber} onChange={handleRecruitmentNumberNewNoticeChange} />
                </div>
            </div>
            <div className="notice-newNotice-3 search ft-r">
                <div className="tit">每人报酬</div>
                <div style={{display: "flex"}}>
                    <input type="number" autocomplete="off" placeholder="每人报酬" className="newNotice-input-box" value={newNotice.rewardPerRecruitment} onChange={handleRerwardNewNoticeChange} />
                </div>
            </div>
            <div className="notice-newNotice-3 search ft-r">
                <div className="tit">截止日期</div>
                <div style={{display: "flex"}}>
                    <input type="date" autocomplete="off" placeholder="截止日期" value={newNotice.deadline} className="newNotice-input-box" onChange={handleDeadlineNewNoticeChange} />
                </div>
            </div>
            <div className="notice-newNotice-2">
                <div className="tit">
                    粉丝数量
                    {newNotice.fansRequirement !== "0+" &&
                    <label className="checkbox-container" style={{marginLeft: "20px"}}>达到要求才可报名
                        <input type="checkbox" onClick={() => handleReqFansReqNewNoticeChange()} className={`${newNotice.requireFansRequirement ? "checked" : ""}`} />
                        <span className="checkmark"></span>
                    </label>
                    }
                </div>
                <div className="ofh">
                    <span className={`notice-newNotice-2-item ${newNotice.fansRequirement === "0+"? "notice-newNotice-2-item-active" : ""}`} onClick={() => handleFansReqNewNoticeChange("0+")}>不限</span>
                    <span className={`notice-newNotice-2-item ${newNotice.fansRequirement === "0-999"? "notice-newNotice-2-item-active" : ""}`} onClick={() => handleFansReqNewNoticeChange("0-999")}>1千以下</span>
                    <span className={`notice-newNotice-2-item ${newNotice.fansRequirement === "1000-4999"? "notice-newNotice-2-item-active" : ""}`} onClick={() => handleFansReqNewNoticeChange("1000-4999")}>1千-5千</span>
                    <span className={`notice-newNotice-2-item ${newNotice.fansRequirement === "5000-9999"? "notice-newNotice-2-item-active" : ""}`} onClick={() => handleFansReqNewNoticeChange("5000-9999")}>5千-1万</span>
                    <span className={`notice-newNotice-2-item ${newNotice.fansRequirement === "10000-49999"? "notice-newNotice-2-item-active" : ""}`} onClick={() => handleFansReqNewNoticeChange("10000-49999")}>1万-5万</span>
                    <span className={`notice-newNotice-2-item ${newNotice.fansRequirement === "50000-99999"? "notice-newNotice-2-item-active" : ""}`} onClick={() => handleFansReqNewNoticeChange("50000-99999")}>5万-10万</span>
                    <span className={`notice-newNotice-2-item ${newNotice.fansRequirement === "100000+"? "notice-newNotice-2-item-active" : ""}`} onClick={() => handleFansReqNewNoticeChange("100000+")}>10万以上</span>
                </div>
            </div>
            <div class="notice-newNotice-2 search ft-r">
                <div className="tit">
                    达人领域
                    {newNotice.areas.length > 0 &&
                    <label className="checkbox-container" style={{marginLeft: "20px"}}>达到要求才可报名
                        <input type="checkbox" onClick={() => handleReqAreaNewNoticeChange()} className={`${newNotice.requireArea ? "checked" : ""}`} />
                        <span className="checkmark"></span>
                    </label>
                    }
                </div>
                <div class="notice-expect-group">
                    {generateExpectCheckboxGroup()}
                </div>
            </div>
            <div className="notice-newNotice-button-group">
                <button className="btn btn-light" onClick={() => setShowAddTable(false)} style={{marginRight: "10px"}}>关闭</button>
                <button className="btn btn-primary" onClick={() => handleAddNotice()}>
                    发布{newNotice.recruitmentNumber * newNotice.rewardPerRecruitment > 0 ? " (会冻结: " + newNotice.recruitmentNumber * newNotice.rewardPerRecruitment + ")" : ""}
                </button>
            </div>
        </div>
    );
}