import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import NoticeApi from '../../api/notice/index.js';

export const NoticeList = (props) => {
    const { i18n, t } = useTranslation();
    const [cookies, setCookie] = useCookies(['auth']);
    const { notices } = props; 

    const getNoticeListItems = () => {
        const items = [];
        for (const i in notices.data) {
            items.push(
                <li className="notice-li cr-p br-4">
                    <div className="notice-list-item">
                        <div className="pic pn-r">
                            {
                                (!notices.data[i].images || notices.data[i].images.length === 0) ?
                                <img src="/images/no_image_available.jpg" /> :
                                <img src={notices.data[i].images[0]} />
                            }
                        </div>
                        <div className="notice-list-intro">
                            <NavLink to={`/service_notice_info?noticeid=${notices.data[i].noticeId}`}>
                                <div className="notice-list-intro-info clear">
                                    <span className="notice-list-intro-title ft-l">
                                        {notices.data[i].title} | {notices.data[i].type === "product" ? "产评" : "探店"}
                                    </span>
                                    <span className="notice-list-intro-platform ft-r">
                                        <img width="16" height="16" src={`/images/medias/icon_${notices.data[i].platform}_rect.png`} className="br-4" />
                                    </span>
                                </div>
                                <div className="notice-list-desc ellipsis2 readed">
                                    {notices.data[i].description}
                                </div>
                                <div className="notice-list-other clear">
                                    <span className="fans">报酬：{notices.data[i].rewardPerRecruitment}</span>
                                    <span className="notice-list-other-line">|</span>
                                    <span className="view">{notices.data[i].viewCount || 0}查看</span>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </li>
            );
        }

        return items;
    }
  
    return ( 
        <div className="notice-list-box">
            {notices.data.length > 0 ?
            <ul className="flex flex-w-w notice-list-ul">{getNoticeListItems()}</ul> :
            (
            <div className="notice-empty-list">
                <img src="/images/empty_notice.png" alt="" style={{verticalAlign: "middle"}} />
            </div>
            )
            }
        </div>
    );
}